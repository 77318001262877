import { FormControlLabel } from '@mui/material';
import React from 'react';
import MuiSwitch from '../../../../controllers/MuiSwitch';
import { ChevronDown } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';

const VideoSettingSection = ({ section_id, videoValue, handleVideoChange }) => {
  const sourceValue = [
    { value: 'youtube', label: 'Youtube' },
    { value: 'vimeo', label: 'Vimeo' },
    // { value: 'dailymotion', label: 'Dailymotion' },
    // { value: 'videopress', label: 'VideoPress' },
    // { value: 'selfhosted', label: 'Self Hosted' },
  ];

  //   const suggestedValue = [
  //     { value: 'anyVideo', label: 'Any Video' },
  //     { value: 'currentChannel', label: 'Current Channel Video' },
  //   ];

  const handleVideoData = (e) => {
    const { name, value, type } = e.target;

    if (type === 'checkbox') {
      handleVideoChange(section_id, e.target.checked, name);
    } else {
      handleVideoChange(section_id, value, name);
    }
  };
  return (
    <>
      <div className='secondary-bg-color w-full space-y-4'>
        <div className='w-full mb-4'>
          <label>{t('lang_source')}</label>
          <div className='relative'>
            <select
              className='w-full mt-2 border border-gray-300 p-2 rounded-lg'
              onChange={handleVideoData}
              name='source'
              value={videoValue?.source}
            >
              {sourceValue.map((obj) => (
                <option key={obj.label} value={obj.value}>
                  {obj.label}
                </option>
              ))}
            </select>
            <div className='select-arrow'></div>
          </div>
        </div>

        <div className='w-full mb-4'>
          <label className='capitalize'>{t('lang_link')}</label>
          <input
            className='w-full px-3 mt-2 py-2 border main-border-color rounded-[6px] focus:outline-none'
            type='text'
            id='group title'
            name='link'
            placeholder='Enter Link here...'
            value={videoValue?.link}
            onChange={handleVideoData}
          />
        </div>

        <div className='w-full mb-4'>
          <div className='flex items-center'>
            <span className='w-full text-sm font-medium text-gray-900 dark:text-gray-300'>
              {t('lang_start_time')}
            </span>
            <input
              type='number'
              name='startTime'
              className='w-full block border focus:border-gray-500 primary-bg-color edit-border rounded-[6px] edit-input focus:outline-none sm:text-sm text-center'
              value={videoValue.startTime}
              onChange={handleVideoData}
            />
          </div>
        </div>
        {videoValue?.source === 'youtube' && (
          <div className='w-full mb-4'>
            <div className='flex items-center'>
              <span className='w-full text-sm font-medium text-gray-900 dark:text-gray-300'>
                {t('lang_end_time')}
              </span>
              <input
                type='number'
                name='endTime'
                className='w-full block border focus:border-gray-500 primary-bg-color edit-border rounded-[6px] edit-input focus:outline-none sm:text-sm text-center'
                value={videoValue.endTime}
                onChange={handleVideoData}
              />
            </div>
          </div>
        )}

        <div className='w-full mb-4'>
          <label className='flex items-center justify-between cursor-pointer'>
            <span className='text-sm font-medium text-gray-900 dark:text-gray-300'>{t('lang_Autoplay')}</span>
            <FormControlLabel
              control={
                <MuiSwitch
                  checked={videoValue?.autoPlay || false}
                  onChange={handleVideoData}
                  name='autoPlay'
                  type='checkbox'
                />
              }
            />
          </label>
        </div>

        <div className='w-full mb-4'>
          <label className='flex items-center justify-between cursor-pointer'>
            <span className='text-sm font-medium text-gray-900 dark:text-gray-300'>{t('lang_mute')}</span>
            <FormControlLabel
              control={
                <MuiSwitch
                  checked={videoValue?.mute || false}
                  onChange={handleVideoData}
                  name='mute'
                  type='checkbox'
                />
              }
            />
          </label>
        </div>

        <div className='w-full mb-4'>
          <label className='flex items-center justify-between cursor-pointer'>
            <span className='text-sm font-medium text-gray-900 dark:text-gray-300'>{t('lang_loop')}</span>
            <FormControlLabel
              control={
                <MuiSwitch
                  checked={videoValue?.loop || false}
                  onChange={handleVideoData}
                  name='loop'
                  type='checkbox'
                />
              }
            />
          </label>
        </div>

        {videoValue?.source === 'youtube' && (
          <div className='w-full mb-4'>
            <label className='flex items-center justify-between cursor-pointer'>
              <span className='text-sm font-medium text-gray-900 dark:text-gray-300'>
                {t('lang_player_control')}
              </span>
              <FormControlLabel
                control={
                  <MuiSwitch
                    checked={videoValue?.playerControl || false}
                    onChange={handleVideoData}
                    name='playerControl'
                    type='checkbox'
                  />
                }
              />
            </label>
          </div>
        )}

        {videoValue?.source === 'youtube' && (
          <div className='w-full mb-4'>
            <label className='flex items-center justify-between cursor-pointer'>
              <span className='text-sm font-medium text-gray-900 dark:text-gray-300'>
                {t('lang_modest_branding')}
              </span>
              <FormControlLabel
                control={
                  <MuiSwitch
                    checked={videoValue?.modestBranding || false}
                    onChange={handleVideoData}
                    name='modestBranding'
                    type='checkbox'
                  />
                }
              />
            </label>
          </div>
        )}

        <div className='w-full mb-4'>
          <label className='flex items-center justify-between cursor-pointer'>
            <span className='text-sm font-medium text-gray-900 dark:text-gray-300'>
              {t('lang_privacy_mode')}
            </span>
            <FormControlLabel
              control={
                <MuiSwitch
                  checked={videoValue?.privacyMode || false}
                  onChange={handleVideoData}
                  name='privacyMode'
                  type='checkbox'
                />
              }
            />
          </label>
        </div>

        <div className='w-full mb-4'>
          <label className='flex items-center justify-between cursor-pointer'>
            <span className='text-sm font-medium text-gray-900 dark:text-gray-300'>{t('lang_lazy_mode')}</span>
            <FormControlLabel
              control={
                <MuiSwitch
                  checked={videoValue?.lazyLoad || false}
                  onChange={handleVideoData}
                  name='lazyLoad'
                  type='checkbox'
                />
              }
            />
          </label>
        </div>

        {videoValue?.source === 'vimeo' && (
          <div className='w-full mb-4'>
            <label className='flex items-center justify-between cursor-pointer'>
              <span className='text-sm font-medium text-gray-900 dark:text-gray-300'>
                {t('lang_intro_title')}
              </span>
              <FormControlLabel
                control={
                  <MuiSwitch
                    checked={videoValue?.introTitle || false}
                    onChange={handleVideoData}
                    name='introTitle'
                    type='checkbox'
                  />
                }
              />
            </label>
          </div>
        )}

        {videoValue?.source === 'vimeo' && (
          <div className='w-full mb-4'>
            <label className='flex items-center justify-between cursor-pointer'>
              <span className='text-sm font-medium text-gray-900 dark:text-gray-300'>
                {t('lang_intro_Portrait')}
              </span>
              <FormControlLabel
                control={
                  <MuiSwitch
                    checked={videoValue?.introPortrait || false}
                    onChange={handleVideoData}
                    name='introPortrait'
                    type='checkbox'
                  />
                }
              />
            </label>
          </div>
        )}

        {videoValue?.source === 'vimeo' && (
          <div className='w-full mb-4'>
            <label className='flex items-center justify-between cursor-pointer'>
              <span className='text-sm font-medium text-gray-900 dark:text-gray-300'>
                {t('lang_intro_Byine')}
              </span>
              <FormControlLabel
                control={
                  <MuiSwitch
                    checked={videoValue?.introByLine || false}
                    onChange={handleVideoData}
                    name='introByLine'
                    type='checkbox'
                  />
                }
              />
            </label>
          </div>
        )}
      </div>
    </>
  );
};

export default VideoSettingSection;
