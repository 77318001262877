import React, { useState } from 'react';

const EditingSchemeFont = ({
  handleCloseColorScheme,
  searchTerm,
  setSearchTerm,
  selectedFont,
  systemFonts,
  otherFonts,
  handleFontSelection,
  filteredFonts,
}) => {
  return (
    <div className='relative h-full p-4 space-y-4'>
      <div
        className='cursor-pointer border-b flex justify-between'
        onClick={handleCloseColorScheme}
      >
        <p className='mb-2'>Select font</p>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='24'
          height='24'
          fill='currentColor'
          className='bi bi-x'
          viewBox='0 0 16 16'
        >
          <path d='M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z' />
        </svg>
      </div>

      <div className='relative'>
        <input
          type='text'
          placeholder='Search'
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className='w-full px-4 py-2 border rounded-md'
        />
      </div>

      <div>
        <p className='text-sm font-semibold'>SYSTEM FONTS</p>
        <p className='text-xs text-gray-500 mt-2'>
          These fonts load faster and might appear differently on various devices.
        </p>
        <ul className='mt-2 space-y-2 border-t'>
          {filteredFonts(systemFonts).map((font) => (
            <li
              key={font}
              className={`cursor-pointer ${selectedFont === font ? 'font-bold' : ''} mt-2 mb-2`}
              onClick={() => handleFontSelection(font)}
            >
              {font}
            </li>
          ))}
        </ul>
      </div>
      <div>
        <p className='text-sm font-semibold'>OTHER FONTS</p>
        <p className='text-xs text-gray-500 mt-2'>
          These fonts are downloaded onto a visitor's computer and might cause slower load times.
        </p>
        <ul className='mt-2'>
          {filteredFonts(otherFonts).map((font) => (
            <li key={font.name} className='space-y-1'>
              <div
                className={`cursor-pointer ${
                  selectedFont === font.name ? 'font-bold' : ''
                }mt-2 mb-2`}
                onClick={() => handleFontSelection(font.name)}
              >
                {font.name}
              </div>
            </li>
          ))}
        </ul>
      </div>

      <div className='absolute flex-col bg-white flex justify-end'>
        <div className='text-sm text-blue-500'>Selected Font: {selectedFont}</div>

        <div className='flex space-x-4'>
          <button className='px-4 py-2 border rounded-md' onClick={handleCloseColorScheme}>
            Cancel
          </button>
          <button className='px-4 py-2 bg-blue-500 text-white rounded-md'>Select</button>
        </div>
      </div>
    </div>
  );
};

export default EditingSchemeFont;
