import { Dialog, Tooltip } from '@mui/material';
import { X } from 'feather-icons-react/build/IconComponents';
import React, { useEffect, useState } from 'react';
import API from '../../../../API';
import { decryptData } from '../../../../controllers/encryptionUtils';
import { t } from 'i18next';

const IconList = ({ isOpen, setIsOpen, section_id, handleIconSelect }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [icondata, setIcondata] = useState([]);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const GetSubscriptionPlans = async () => {
    if (isLoading) return;
    setIsLoading(true);

    try {
      const ApiCall = await API.get(
        `/admin/layout/getIcons?page=${page}&limit=40&searchkey=${searchQuery}`,
      );
      if (ApiCall.status === 200 || ApiCall.status === 304) {
        const res = decryptData(ApiCall?.data);
        if (page === 1) {
          setIcondata(res?.data?.records);
        } else {
          setIcondata((prevIcons) => [...prevIcons, ...res?.data?.records]);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      setPage(1);
      setIcondata([]);
      GetSubscriptionPlans();
    }
  }, [isOpen, searchQuery]);

  const filteredIcons = icondata?.filter((icon) => {
    const iconName = String(icon?.name || '');
    return iconName.toLowerCase().includes(searchQuery.toLowerCase());
  });

  const handleDialogClose = () => {
    setIsOpen(false);
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
    setPage(1);
    setIcondata([]);
  };

  const handleScroll = (e) => {
    const bottom = e.target.scrollHeight === e.target.scrollTop + e.target.clientHeight;
    if (bottom) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  useEffect(() => {
    if (page > 1) {
      GetSubscriptionPlans();
    }
  }, [page]);

  return (
    <Dialog keepMounted open={isOpen}>
      <div className='p-4 w-[700px] h-[70vh] overflow-hidden flex flex-col'>
        <div className='flex justify-between'>
          <h6>{t('lang_icon_library')}</h6>
          <X className='cursor-pointer' onClick={handleDialogClose} />
        </div>

        <div className='my-2'>
          <input
            type='text'
            placeholder='Search icons...'
            className='p-2 border rounded-[6px] w-full'
            value={searchQuery}
            onChange={handleSearch}
          />
        </div>

        <div className='grid grid-cols-5 gap-2 overflow-y-auto p-1' onScroll={handleScroll}>
          {filteredIcons?.length > 0 ? (
            filteredIcons?.map((icon) => (
              <div
                className='border-[1px] border-solid border-[#e5e5e5] rounded-md cursor-pointer hover:bg-[#F0F0F0]'
                onClick={() => handleIconSelect(icon?.component)}
                key={icon?.id}
              >
                <div className='p-2'>
                  <div className='flex flex-col items-center text-center'>
                    <div dangerouslySetInnerHTML={{ __html: icon?.component }} />
                    <Tooltip title={icon?.name}>
                      <p className='text-center mt-1 element-title'>{icon?.name}</p>
                    </Tooltip>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p>{t('lang_no_icons_found')}</p>
          )}

          {isLoading && <p>{t('lang_loading')}...</p>}
        </div>
      </div>
    </Dialog>
  );
};

export default IconList;
