export const staticCartData = {
    "_id": "664703ac79d94495acd3e602",
    "tenant": "64b4c46baf2d02678f262a00",
    "user": "65cb5019d868c38f0a39ab6f",
    "client": {
        "_id": "64b4c46caf2d02678f262a0a",
        "business_address": {
            "addressLine1": "4030, Central Bazzar, opp. Varachha Polica Station",
            "addressLine2": "Varachha Main Road, Surat, Gujarat 395006 aaaaaaaaaaaaaaaaa",
            "city": "SURAT",
            "state": "Gujarat",
            "pincode": "395006",
            "country": "India"
        }
    },
    "products": [
        {
            "product": {
                "dimension": {
                    "length": 10,
                    "breadth": 10,
                    "height": 10
                },
                "_id": "65ebdee60cb556439b2648be",
                "weight": 0.5
            },
            "image": "https://cdn.shopeasy.ai/shopeasy/product/pexels-pixabay-157675.jpg",
            "name": "Luxury Perfume - Floral/Woody",
            "product_slug_name": "luxury-perfume-floralwoody",
            "dimension": {
                "length": 10,
                "breadth": 10,
                "height": 10
            },
            "weight": 0.5,
            "selling_price": 1000,
            "quantity": 1,
            "product_total": 1000,
            "product_total_discount": 0,
            "discount_amount": 0,
            "min_order_quantity": 1,
            "product_return_policy": "non_returnable",
            "product_replacement_days": 7,
            "_id": "664703ac79d94495acd3e603"
        }
    ],
    "shipping_method": {
        "_id": "64d4af643c8f791858c9c61c",
        "tenant": "64b4c46baf2d02678f262a00",
        "user": "64cc78f29907de9f8b0bed5c",
        "client": "64b4c46caf2d02678f262a0a",
        "shipping_method_name": "shop drop off",
        "shipping_charge": 0,
        "status": "enabled",
        "default": true,
        "sort_order": 3,
        "createdAt": "2023-08-10T09:35:32.520Z",
        "updatedAt": "2024-04-30T05:43:38.923Z",
        "shippingmethod_id": 3
    },
    "shipping_charge": 0,
    "grand_total": 1000,
    "total_discount": 0,
    "mail_Send24": false,
    "mail_Send48": false,
    "createdAt": "2024-05-17T07:13:48.815Z",
    "updatedAt": "2024-05-17T07:13:48.815Z",
    "cart_id": 226
}