import React, { useEffect, useState } from 'react';
import DarkModeToggle from '../Main/DarkModeToggle';
import API from '../../API';
import { useNavigate } from 'react-router-dom';
import { Settings } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import { decryptData } from '../../controllers/encryptionUtils';

const EditorNavbar = ({ themeSetting, handleTheameSetting, handlepPageChange }) => {
  const [layouts, setLayouts] = useState([]);
  const [page, setPage] = useState( location?.pathname?.split('/').pop());
  const getLayoutName = async () => {
    try {
      const Apicall = await API.get(`/admin/layout/getLayouts`);
      const result = decryptData(Apicall?.data)
      setLayouts([
        ...result?.data,
      ]);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getLayoutName();
  }, []);
  // function getInitialPage() {
  //     const urlParams = new URLSearchParams(window.location.search);
  //     const initialPage = urlParams.get('page');
  //     return initialPage ? initialPage : ""; // Adjust for zero-based indexing
  // }
  // function replacePageInUrl(page) {
  //     if(page?.length > 1){
  //         const currentUrl = new URLSearchParams(window.location.search);
  //         currentUrl.set('page', page);
  //         window.history.pushState({}, '', window.location.pathname + '?' + currentUrl.toString());
  //     }
  // }
  // useEffect(() => {
  //     replacePageInUrl(page)
  //     handlepPageChange(page)
  // }, [page])
  const navigate = useNavigate();
  useEffect(() => {
    if (page?.length > 0) {
      navigate(`/layout/${page}`);
      handlepPageChange(page);
    }
  }, [page]);
  return (
    <div className='px-4 secondary-bg-color table-text border-b main-border-color  z-10 w-full max-w-full md:rounded-t-lg'>
      <div className='flex items-center justify-between'>
        <div className='flex items-center space-x-3'>
          <span className='flex bg-green-200 font-medium text-green-700 items-center text-[12px] px-2 py-0.5 rounded-[6px]'>
            <span className='w-2 h-2 me-2 bg-green-700 rounded-full'></span> {t('lang_Live')}
          </span>
          <button
            title='Theme setting'
            onClick={(e) => {
              e.preventDefault();
              handleTheameSetting(!themeSetting);
            }}
          >
            <Settings className='w-4 h-4' />
          </button>
        </div>
        <div className='relative'>
          <select value={page} onChange={(e) => setPage(e.target.value)}>
            {layouts?.map((elem) => {
              return <option value={elem?.layout_slug_name}>{elem?.layout_title}</option>;
            })}
          </select>
          <div className='select-arrow'></div>
        </div>
        <DarkModeToggle />
      </div>
    </div>
  );
};

export default EditorNavbar;
