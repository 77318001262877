import { FormControlLabel } from '@mui/material';
import { t } from 'i18next';
import React, { useEffect, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import GenerateLiveEditiorTitle from '../../../../../controllers/GenerateLiveEditiorTitle';
import MuiSwitch from '../../../../../controllers/MuiSwitch';
import module from '../../../../../controllers/QuillToolBar';
import SvgSection from '../../../../../controllers/SvgSection';
import IconList from '../IconList';

const ListItem = ({
  dropDownValue,
  item,
  handleItemClick1,
  isSelected,
  handleInputChange,
  onCopy,
  onRemove,
  isHovered,
  setIsHovered,
  handleIconClick,
  handleSvgClick,
  handleIconFill,
}) => {
  return (
    <div
      className={`p-3 mb-2 mt-2 border border-gray-200 rounded-md ${
        isSelected ? 'bg-gray-100 divide-slate-400 divide-y' : ''
      }`}
    >
      <div className='flex items-center justify-between'>
        <div className='flex items-center'>
          <span className='text-sm font-medium'>{item?.title}</span>
        </div>
        <div className='flex items-center space-x-3'>
          <button
            className='text-gray-400 hover:text-gray-600'
            title='Edit'
            onClick={handleItemClick1}
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='16'
              height='16'
              fill='currentColor'
              className='bi bi-pencil-square'
              viewBox='0 0 16 16'
            >
              <path d='M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z' />
              <path
                fillRule='evenodd'
                d='M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z'
              />
            </svg>
          </button>
          <button
            className='text-gray-400 hover:text-gray-600'
            title='Duplicate'
            onClick={() => onCopy(item)}
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='16'
              height='16'
              fill='currentColor'
              className='bi bi-copy'
              viewBox='0 0 16 16'
            >
              <path
                fillRule='evenodd'
                d='M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z'
              />
            </svg>
          </button>
          <button
            className='text-red-400 hover:text-red-600'
            title='Remove'
            onClick={() => onRemove(item?.id)}
          >
            <svg
              className='w-5 h-5'
              fill='none'
              stroke='currentColor'
              strokeWidth='2'
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 24 24'
            >
              <path d='M18 6l-12 12M6 6l12 12' />
            </svg>
          </button>
        </div>
      </div>
      {isSelected && (
        <div className='mt-2'>
          <div className='w-full mb-4'>
            <label htmlFor='title'>{t('lang_Title')}</label>
            <input
              className='w-full px-3 mt-2 py-2 border main-border-color rounded-[6px] focus:outline-none bg-white'
              type='text'
              id='title'
              name='title'
              placeholder='Enter title...'
              value={item?.title || ''}
              onChange={handleInputChange}
            />
          </div>

          <div className='w-full mb-4'>
            <label>{t('lang_htmltag')}</label>
            <div className='relative'>
              <select
                className='w-full mt-2'
                id='htmlTag'
                name='htmlTag'
                value={item?.htmlTag || ''}
                onChange={handleInputChange}
              >
                {dropDownValue?.map((obj) => (
                  <option key={obj?.value} value={obj?.value}>
                    {obj?.label}
                  </option>
                ))}
              </select>
              <div className='select-arrow'></div>
            </div>
          </div>
          <div className='mt-2'>
            <label htmlFor='text'>{t('lang_title_icon')}</label>
            <div
              className='relative flex justify-center p-4 bg-[#e6e8ea] border-[1px] border-[#bcbdbf]'
              onMouseEnter={() => {
                setIsHovered(true);
              }}
              onMouseLeave={() => {
                setIsHovered(false);
              }}
            >
              {item?.icon &&
                (React.isValidElement(item?.icon) ? (
                  React.cloneElement(item?.icon, { width: '22px', height: '22px' })
                ) : (
                  <SvgSection svgContent={item?.icon} width='22px' height='22px' />
                ))}

              {isHovered && (
                <>
                  <button
                    className='text-sm absolute bottom-0 left-0 bg-black text-white p-2 rounded-[6px]'
                    onClick={() => handleIconClick('iconList')}
                  >
                    {t('lang_icon_library')}
                  </button>
                  <button
                    className='text-sm absolute bottom-0 right-0 bg-black text-white p-2 rounded-[6px]'
                    onClick={() => handleSvgClick()}
                  >
                    {t('lang_svg_icon')}
                  </button>
                </>
              )}
            </div>
          </div>

          <div className='w-full mb-4 mt-4'>
            <label className='flex items-center justify-between cursor-pointer'>
              <span className='text-sm font-medium text-gray-900 dark:text-gray-300'>
              {t('lang_icon_fill')}
              </span>
              <FormControlLabel
                control={
                  <MuiSwitch
                    checked={item?.isFill || false}
                    onChange={handleIconFill}
                    name='isFill'
                    type='checkbox'
                  />
                }
              />
            </label>
          </div>
        </div>
      )}
    </div>
  );
};

const StaticSevenFeatureSection = ({
  section_id,
  sevenFeatureValue,
  setSevenFeatureValue,
  handleSevenFeatureValue,
  tabIndex,
  setTabIndex,
  handleAddSevenFeatureItem,
  handleCopySevenFeatureItem,
  handleSevenFeatureDataRemoveValue,
  sectionGridContent,
  handleSevenFeatureGridValueChange,
  sectionContent,
  handleSevenFeatureTitleValueChange,
  section_type,
  subTitleheadingLevel,
  handleSevenFeatureTitleTagChange,
  descriptionContent,
  handleSevenFeatureDescriptionChange,
  sectionButtonContent,
  handleSevenFeatureButtonValueChange,
}) => {
  const dropDownValue = [
    { value: 'h1', label: 'H1' },
    { value: 'h2', label: 'H2' },
    { value: 'h3', label: 'H3' },
    { value: 'h4', label: 'H4' },
    { value: 'h5', label: 'H5' },
    { value: 'h6', label: 'H6' },
    { value: 'div', label: 'Div' },
    { value: 'span', label: 'Span' },
    { value: 'p', label: 'P' },
  ];
  const dropDownGridValue = [
    { value: '1', label: '1' },
    { value: '2', label: '2' },
    { value: '3', label: '3' },
    { value: '4', label: '4' },
    { value: '5', label: '5' },
    { value: '6', label: '6' },
  ];
  const [gridValue, setGridValue] = useState(sectionGridContent);
  const [groupTitle, setGroupTitle] = useState(sectionContent);
  const [buttonTitle, setButtonTitle] = useState(sectionButtonContent);
  const [selectedSubTitleHeadingLevel, setSelectedSubTitleHeadingLevel] = useState(
    subTitleheadingLevel || 'h2',
  );

  const ShortquillRef = useRef(null);
  const fileInputRef = useRef(null);

  const [shortdescription, setShortDescription] = useState(descriptionContent);

  const [meta, setMeta] = useState({
    meta_description: '',
  });

  const getAiGeneratedTitle = (value) => {
    setGroupTitle(value);
    handleSevenFeatureTitleValueChange(section_id, value);
  };

  const onTitleContentChange = (e) => {
    const newValue = e.target.value;
    setGroupTitle(newValue);
    handleSevenFeatureTitleValueChange(section_id, newValue);
  };

  const handleSubTitleHeadingTagChange = (e) => {
    const newTag = e.target.value;
    setSelectedSubTitleHeadingLevel(newTag);
    handleSevenFeatureTitleTagChange(section_id, newTag);
  };

  const handleShortDescQuillChange = (value) => {
    setShortDescription(value);
    handleSevenFeatureDescriptionChange(section_id, value);
  };

  useEffect(() => {
    setShortDescription(descriptionContent);
    setGroupTitle(sectionContent);
    setButtonTitle(sectionButtonContent);
    setSelectedSubTitleHeadingLevel(subTitleheadingLevel);
    setGridValue(sectionGridContent);
  }, [
    descriptionContent,
    sectionContent,
    sectionButtonContent,
    subTitleheadingLevel,
    sectionGridContent,
  ]);

  const handleItemClick = (index) => {
    setTabIndex((prev) => (prev === index ? null : index));
    const updatedfirstContentValue = {
      ...sevenFeatureValue,
      item: sevenFeatureValue?.item?.map((item, i) => ({
        ...item,
        isEdit: i === index,
      })),
    };
    setSevenFeatureValue(updatedfirstContentValue);
    handleSevenFeatureValue(section_id, updatedfirstContentValue);
  };
  const addItem = () => {
    const newId = sevenFeatureValue?.item?.length
      ? sevenFeatureValue?.item?.[sevenFeatureValue?.item?.length - 1]?.id + 1
      : 1;
    const newItem = {
      id: newId,
      title: 'Lorem',
      htmlTag: 'h2',
      isEdit: false,
      icon: ` <svg
      fill='none'
      stroke='currentColor'
      stroke-linecap='round'
      stroke-linejoin='round'
      stroke-width='3'
      class='text-indigo-500 w-6 h-6 flex-shrink-0 mr-4'
      viewBox='0 0 24 24'
   >
      <path d='M22 11.08V12a10 10 0 11-5.93-9.14'></path>
      <path d='M22 4L12 14.01l-3-3'></path>
  </svg>`,
      isFill: false,
    };
    const updatedItems = sevenFeatureValue?.item?.map((item) => ({
      ...item,
      isEdit: false,
    }));
    const finalItems = [...updatedItems, newItem];
    handleAddSevenFeatureItem(section_id, finalItems);
    setTabIndex(null);
  };

  const copyItem = (item, index) => {
    handleCopySevenFeatureItem(section_id, item, index);
  };

  const removeItem = (id) => {
    handleSevenFeatureDataRemoveValue(section_id, id);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    const updatedfirstContentValue = {
      ...sevenFeatureValue,
      item: sevenFeatureValue?.item?.map((item, i) =>
        i === tabIndex ? { ...item, [name]: value } : item,
      ),
    };

    setSevenFeatureValue(updatedfirstContentValue);
    handleSevenFeatureValue(section_id, updatedfirstContentValue);
  };

  const onButtonContentChange = (e) => {
    const newValue = e.target.value;
    setButtonTitle(newValue);
    handleSevenFeatureButtonValueChange(section_id, newValue);
  };

  const onGridContentChange = (e) => {
    const newValue = e.target.value;
    setGridValue(newValue);
    handleSevenFeatureGridValueChange(section_id, newValue);
  };

  const [isHovered, setIsHovered] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const handleIconClick = (value) => {
    if (value === 'iconList') {
      setIsOpen(true);
    }
  };

  const handleIconSelect = (iconComponent) => {
    const updatedFirstContentValue = {
      ...sevenFeatureValue,
      item: sevenFeatureValue?.item?.map((item, i) =>
        i === tabIndex ? { ...item, icon: iconComponent } : item,
      ),
    };

    setSevenFeatureValue(updatedFirstContentValue);
    handleSevenFeatureValue(section_id, updatedFirstContentValue);
    setIsOpen(false);
  };

  const handleSvgClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type === 'image/svg+xml') {
      const reader = new FileReader();
      reader.onload = (e) => {
        const imageUrl = e.target.result;
        handleIconSelect(imageUrl);
      };
      reader.readAsText(file);
    } else {
      alert('Please select a valid SVG file.');
    }
  };

  const handleIconFill = (e) => {
    const { checked, name } = e.target;
    const updatedEightContentValue = {
      ...sevenFeatureValue,
      item: sevenFeatureValue?.item?.map((item, i) =>
        i === tabIndex ? { ...item, [name]: checked } : item,
      ),
    };

    setSevenFeatureValue(updatedEightContentValue);
    handleSevenFeatureValue(section_id, updatedEightContentValue);
  };

  return (
    <div className='max-w-md mx-auto mt-4'>
      <div className='mb-4'>
        <label htmlFor='group title' className='flex justify-between'>
          <span htmlFor='image'>{t('lang_Title')}</span>
          <GenerateLiveEditiorTitle
            getAiGeneratedTitle={getAiGeneratedTitle}
            section_type={section_type}
          />
        </label>
        <textarea
          className='border w-full outline-none rounded-[6px] main-border-color px-4 py-2 text-sm mt-2'
          placeholder='Add your heading text here...'
          value={groupTitle}
          onChange={onTitleContentChange}
        />
        <label>{t('lang_title_hTMLTag')}</label>
        <div className='relative'>
          <select
            className='w-full mt-2'
            value={selectedSubTitleHeadingLevel}
            onChange={handleSubTitleHeadingTagChange}
          >
            {dropDownValue?.map((obj) => (
              <option key={obj?.value} value={obj?.value}>
                {obj?.label}
              </option>
            ))}
          </select>
          <div className='select-arrow'></div>
        </div>
      </div>
      <div>
        <label>{t('lang_Description')}</label>
        <ReactQuill
          modules={module}
          ref={ShortquillRef}
          className='w-full h-auto table-text rounded-[6px] mt-2'
          theme='snow'
          name='description'
          // value={shortdescription?.length ? shortdescription.split(' ').slice(0, 280).join(' ') : ''}
          value={sevenFeatureValue?.description}
          onChange={handleShortDescQuillChange}
          placeholder='Enter description'
          onBlur={() => {
            setMeta((prevMeta) => ({
              ...prevMeta,
              meta_description:
                shortdescription
                  ?.replace(/<[^>]*>/g, ' ')
                  ?.split(' ')
                  .slice(0, 50)
                  .join(' ') || '',
            }));
          }}
        />
      </div>

      <div className='mt-4'>
        <label>{t('lang_Section')}</label>
        {Array.isArray(sevenFeatureValue?.item) &&
          sevenFeatureValue?.item?.map((item, index) => (
            <ListItem
              key={index}
              item={item}
              handleItemClick1={() => handleItemClick(index)}
              isSelected={tabIndex === index}
              onCopy={() => copyItem(item, index)}
              onRemove={removeItem}
              handleInputChange={handleInputChange}
              dropDownValue={dropDownValue}
              isHovered={isHovered}
              setIsHovered={setIsHovered}
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              handleIconClick={handleIconClick}
              handleIconSelect={handleIconSelect}
              handleSvgClick={handleSvgClick}
              handleIconFill={handleIconFill}
            />
          ))}
      </div>

      <button
        onClick={addItem}
        className='mt-4 px-4 py-2 bg-gray-600 text-white font-medium rounded-md hover:bg-gray-700 focus:outline-none'
      >
        + {t('lang_add_item')}
      </button>

      <div className='mt-4'>
        <label>{t('lang_button_title')}</label>
        <input
          className='border w-full outline-none rounded-[6px] main-border-color px-4 py-2 text-sm mt-2'
          placeholder='Add your heading text here...'
          value={buttonTitle}
          onChange={onButtonContentChange}
        />
      </div>

      <div className='my-4'>
        <label>{t('lang_Grid')}</label>
        <div className='relative'>
          <select className='w-full mt-2' value={gridValue} onChange={onGridContentChange}>
            {dropDownGridValue?.map((obj) => (
              <option key={obj?.value} value={obj?.value}>
                {obj?.label}
              </option>
            ))}
          </select>
          <div className='select-arrow'></div>
        </div>
      </div>
      {isOpen ? (
        <IconList
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          section_id={section_id}
          handleIconSelect={handleIconSelect}
        />
      ) : (
        ''
      )}
      <div className='w-full mb-4 mt-2'>
        <input
          type='file'
          accept='.svg'
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={handleFileChange}
        />
      </div>
    </div>
  );
};

export default StaticSevenFeatureSection;
