import { t } from 'i18next';
import React, { useState } from 'react';
import ColorSectionSetting from './SettingPageContainers.js/ColorSectionSetting';
import EditingSchemeColor from './SettingPageContainers.js/EditingSchemeColor';
import LogoSectionSetting from './SettingPageContainers.js/LogoSectionSetting';
import TypographySectionSetting from './SettingPageContainers.js/TypographySectionSetting';
import EditingSchemeFont from './SettingPageContainers.js/EditingSchemeFont';
import LayoutSectionSetting from './SettingPageContainers.js/LayoutSectionSetting';

const SettingPageSection = () => {
  const [rangeValue, setRangeValue] = useState(50);
  const [colorArray, setColorArray] = useState([]);
  const [openColorScheme, setOpenColorScheme] = useState();
  const [selectedIndex, setSelectedIndex] = useState(null);

  console.log(colorArray, 'colorArray');

  const handleRangeChange = (e) => {
    const value = Math.max(50, Math.min(300, Number(e.target.value)));
    setRangeValue(value);
  };

  const [fontValues, setFontValues] = useState({
    headingFontValue: 100,
    bodyFontValue: 100,
  });

  const handleFontRangeChange = (key) => (e) => {
    console.log(key, 'key-=->');
    const value = Math.max(100, Math.min(150, Number(e.target.value)));
    setFontValues((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const [searchTerm, setSearchTerm] = useState('');
  const [selectedFont, setSelectedFont] = useState('Abril Fatface');
  const systemFonts = ['Mono', 'Sans-serif', 'Serif'];
  const otherFonts = [
    { name: 'Abel' },
    { name: 'Abril Fatface' },
    { name: 'Alegreya' },
    { name: 'Alegreya Sans' },
    { name: 'Assistant' },
  ];

  const handleFontSelection = (font, style) => {
    setSelectedFont(style ? `${font} (${style})` : font);
  };

  const filteredFonts = (fonts) =>
    fonts.filter(
      (font) =>
        font.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        font.toLowerCase().includes(searchTerm.toLowerCase()),
    );

  const [openState, setOpenState] = useState();
  const [colorTheme, setColorTheme] = useState({
    background: '#818181',
    text: '#FFFFFF',
    solid_button_background: '#FFFFFF',
    solid_button_text: '#000000',
    outlined_button: '#000000',
    outlined_button_text: '#ffffff',
  });

  const handleColorChange = (value) => {
    if (openState === 'background') {
      setColorTheme({ ...colorTheme, background: value });
    } else if (openState === 'text') {
      setColorTheme({ ...colorTheme, text: value });
    } else if (openState === 'solid_button_background') {
      setColorTheme({ ...colorTheme, solid_button_background: value });
    } else if (openState === 'solid_button_text') {
      setColorTheme({ ...colorTheme, solid_button_text: value });
    } else if (openState === 'outlined_button') {
      setColorTheme({ ...colorTheme, outlined_button: value });
    } else if (openState === 'outlined_button_text') {
      setColorTheme({ ...colorTheme, outlined_button_text: value });
    }
  };

  const handleCloseColor = () => {
    setOpenState('');
  };

  const handleAddToArray = (value) => {
    setOpenColorScheme(value);
    setSelectedIndex(null);
    if (value === 'color_scheme') {
      setColorTheme({
        background: '#818181',
        text: '#FFFFFF',
        solid_button_background: '#FFFFFF',
        solid_button_text: '#000000',
        outlined_button: '#000000',
        outlined_button_text: '#ffffff',
      });
    }
  };

  const handleEditScheme = (index) => {
    setOpenColorScheme('color_scheme');
    setSelectedIndex(index);
    setColorTheme(colorArray[index]);
  };

  const handleCloseColorScheme = () => {
    setOpenColorScheme('');
    if (selectedIndex !== null) {
      setColorArray((prevArray) =>
        prevArray.map((item, index) => (index === selectedIndex ? { ...colorTheme } : item)),
      );
    } else {
      setColorArray((prevArray) => [
        ...prevArray,
        {
          ...colorTheme,
        },
      ]);
    }
    setSelectedIndex(null);
  };

  return (
    <div className='theme-setting mt-4'>
      {openColorScheme === 'color_scheme' ? (
        <EditingSchemeColor
          colorTheme={colorTheme}
          openState={openState}
          setOpenState={setOpenState}
          handleColorChange={handleColorChange}
          handleCloseColor={handleCloseColor}
          colorArray={colorArray}
          handleCloseColorScheme={handleCloseColorScheme}
        />
      ) : openColorScheme === 'font_scheme' ? (
        <EditingSchemeFont
          handleCloseColorScheme={handleCloseColorScheme}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          selectedFont={selectedFont}
          systemFonts={systemFonts}
          otherFonts={otherFonts}
          handleFontSelection={handleFontSelection}
          filteredFonts={filteredFonts}
        />
      ) : (
        <>
          <p className='font-medium text-primary text-base px-3'>{t('lang_themt_setting')}</p>
          <hr className='my-3 border-t main-border-color' />
          <LogoSectionSetting rangeValue={rangeValue} handleRangeChange={handleRangeChange} />
          <ColorSectionSetting
            colorArray={colorArray}
            handleAddToArray={handleAddToArray}
            handleEditScheme={handleEditScheme}
          />
          <TypographySectionSetting
            colorArray={colorArray}
            handleAddToArray={handleAddToArray}
            handleEditScheme={handleEditScheme}
            handleFontRangeChange={handleFontRangeChange}
            fontValues={fontValues}
            selectedFont={selectedFont}
          />
          <LayoutSectionSetting
            colorArray={colorArray}
            handleAddToArray={handleAddToArray}
            handleEditScheme={handleEditScheme}
            handleFontRangeChange={handleFontRangeChange}
            fontValues={fontValues}
            selectedFont={selectedFont}
          />
        </>
      )}
    </div>
  );
};

export default SettingPageSection;
