import { t } from 'i18next';
import React, { useEffect, useState } from 'react';

const CounterSettingSection = ({ section_id, numberCounter, handleCounterChange }) => {
  const [selectedHeadingLevel, setSelectedHeadingLevel] = useState(numberCounter?.htmlTag || 'h2');
  const [inputValues, setInputValues] = useState({});

  const NumberValueChange = (e) => {
    const { name, value } = e.target;
    const numericFields = ['start', 'end', 'duration'];
    const updatedValue = numericFields?.includes(name) ? parseInt(value, 10) : value;
    if (numericFields?.includes(name) && (isNaN(updatedValue) || value === '')) {
      setInputValues((prev) => ({ ...prev, [name]: value }));
      return;
    }
    handleCounterChange(section_id, name, updatedValue);
    setInputValues((prev) => ({ ...prev, [name]: updatedValue }));
  };
  const dropDownValue = [
    { value: 'h1', label: 'H1' },
    { value: 'h2', label: 'H2' },
    { value: 'h3', label: 'H3' },
    { value: 'h4', label: 'H4' },
    { value: 'h5', label: 'H5' },
    { value: 'h6', label: 'H6' },
    { value: 'div', label: 'Div' },
    { value: 'span', label: 'Span' },
    { value: 'p', label: 'P' },
  ];

  useEffect(() => {
    setSelectedHeadingLevel(numberCounter?.htmlTag);
  }, [numberCounter?.htmlTag]);
  return (
    <>
      <div className='w-full mb-4 mt-2'>
        <div className='w-full mb-4'>
          <label htmlFor='start'>{t('lang_starting_number')}</label>
          <input
            className='w-full px-3 mt-2 py-2 border main-border-color rounded-[6px] focus:outline-none'
            type='number'
            id='start'
            name='start'
            placeholder='Enter Number...'
            onChange={NumberValueChange}
            value={
              inputValues?.start !== undefined
                ? inputValues?.start
                : numberCounter?.start >= 0
                ? numberCounter?.start
                : ''
            }
          />
        </div>
        <div className='w-full mb-4'>
          <label htmlFor='end capitalize'>{t('lang_ending_number')}</label>
          <input
            className='w-full px-3 mt-2 py-2 border main-border-color rounded-[6px] focus:outline-none'
            type='number'
            id='end'
            name='end'
            placeholder='Enter Number...'
            onChange={NumberValueChange}
            value={
              inputValues?.end !== undefined
                ? inputValues?.end
                : numberCounter?.end >= 0
                ? numberCounter?.end
                : ''
            }
          />
        </div>
        <div className='w-full mb-4'>
          <label htmlFor='duration'>{t('lang_animaton_duration')}</label>
          <input
            className='w-full px-3 mt-2 py-2 border main-border-color rounded-[6px] focus:outline-none'
            type='number'
            id='duration'
            name='duration'
            placeholder='Enter Number...'
            onChange={NumberValueChange}
            value={
              inputValues?.duration !== undefined
                ? inputValues?.duration
                : numberCounter?.duration >= 0
                ? numberCounter?.duration
                : ''
            }
          />
        </div>
        <div className='w-full mb-4'>
          <label htmlFor='prefix'>{t('lang_number_prefix')}</label>
          <input
            className='w-full px-3 mt-2 py-2 border main-border-color rounded-[6px] focus:outline-none'
            type='text'
            id='prefix'
            name='prefix'
            placeholder='Enter Prefix...'
            onChange={NumberValueChange}
            value={numberCounter?.prefix || ''}
          />
        </div>
        <div className='w-full mb-4'>
          <label htmlFor='suffix'>{t('lang_number_suffix')}</label>
          <input
            className='w-full px-3 mt-2 py-2 border main-border-color rounded-[6px] focus:outline-none'
            type='text'
            id='suffix'
            name='suffix'
            placeholder='Enter Suffix...'
            onChange={NumberValueChange}
            value={numberCounter?.suffix || ''}
          />
        </div>
        <div className='w-full mb-4'>
          <label htmlFor='Title'>{t('lang_title')}</label>
          <input
            className='w-full px-3 mt-2 py-2 border main-border-color rounded-[6px] focus:outline-none'
            type='text'
            id='title'
            name='title'
            placeholder='Enter Number...'
            onChange={NumberValueChange}
            value={numberCounter?.title}
          />
        </div>
        <div className='w-full'>
          <label>{t('lang_htmltag')}</label>
          <div className='relative'>
            <select
              className='w-full mt-2'
              name='htmlTag'
              value={selectedHeadingLevel}
              onChange={NumberValueChange}
            >
              {dropDownValue?.map((obj) => (
                <option key={obj?.value} value={obj?.value}>
                  {obj?.label}
                </option>
              ))}
            </select>
            <div className='select-arrow'></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CounterSettingSection;
