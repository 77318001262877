import React, { useState } from 'react';
import ColorPicker from '../../../Color/ColorPicker';
import { t } from 'i18next';

const EditingSchemeColor = ({
  colorTheme,
  openState,
  setOpenState,
  handleColorChange,
  handleCloseColor,
  handleCloseColorScheme,
}) => {
  return (
    <div className='p-4 space-y-2'>
      <div className='flex items-center space-x-2'>
        <div className='cursor-pointer' onClick={handleCloseColorScheme}>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='16'
            height='16'
            fill='currentColor'
            class='bi bi-chevron-left'
            viewBox='0 0 16 16'
          >
            <path
              fill-rule='evenodd'
              d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0'
            />
          </svg>
        </div>
        <div>
          <p>Colors</p>
          <p className='font-semibold'>Editing Scheme</p>
        </div>
      </div>

      <div className='flex items-center space-x-2 mt-4'>
        <div
          style={{ backgroundColor: colorTheme?.background }}
          className='p-5 h-14 w-20 rounded-[10px] flex flex-col justify-center items-center'
        >
          <h5
            style={{
              color: colorTheme?.text,
            }}
          >
            Aa
          </h5>
          <div className='space-x-2 flex'>
            <div
              className='h-2 w-5 rounded-[10px]'
              style={{
                backgroundColor: colorTheme?.solid_button_background,
                color: colorTheme?.solid_button_text,
              }}
            ></div>
            <div
              className='h-2 w-5 rounded-[10px] border-[1px] border-solid'
              style={{
                borderColor: colorTheme?.outlined_button,
                color: colorTheme?.outlined_button_text,
              }}
            ></div>
          </div>
        </div>

        <div>
          <p className='!text-[10px]'>
            Editing this scheme's colors will affect all sections that use this scheme.
          </p>
        </div>
      </div>

      <hr className='my-3 border-t main-border-color' />

      <div className='mt-4'>
        <div className='w-full flex items-center'>
          <label className='text-[12px] font-medium text-gray-700 w-[40%]'>
            {t('lang_background')}
          </label>
          <div className='relative w-full'>
            <div
              onClick={() => setOpenState('background')}
              className='cursor-pointer flex items-center border main-border-color p-1.5 rounded-[6px] space-x-2'
            >
              <div
                className='w-[30px] shadow h-[30px] rounded-[6px] cursor-pointer'
                style={{ backgroundColor: colorTheme?.background }}
              ></div>
              <span className='text-sm font-medium table-text'>{colorTheme?.background}</span>
            </div>
            {openState === 'background' && (
              <ColorPicker
                handleChange={handleColorChange}
                setting={colorTheme?.background}
                handleCloseColor={handleCloseColor}
              />
            )}
          </div>
        </div>
        <div className='w-full flex items-center mt-4'>
          <label className='text-[12px] font-medium text-gray-700 w-[40%]'>{t('lang_text')}</label>
          <div className='relative w-full'>
            <div
              onClick={() => setOpenState('text')}
              className='cursor-pointer flex items-center border main-border-color p-1.5 rounded-[6px] space-x-2'
            >
              <div
                className='w-[30px] shadow h-[30px] rounded-[6px] cursor-pointer'
                style={{ backgroundColor: colorTheme?.text }}
              ></div>
              <span className='text-sm font-medium table-text'>{colorTheme?.text}</span>
            </div>
            {openState === 'text' && (
              <ColorPicker
                handleChange={handleColorChange}
                setting={colorTheme?.text}
                handleCloseColor={handleCloseColor}
              />
            )}
          </div>
        </div>
        <div className='w-full flex items-center mt-4'>
          <label className='text-[12px] font-medium text-gray-700 w-[40%]'>
            {t('lang_solid_button_background')}
          </label>
          <div className='relative w-full'>
            <div
              onClick={() => setOpenState('solid_button_background')}
              className='cursor-pointer flex items-center border main-border-color p-1.5 rounded-[6px] space-x-2'
            >
              <div
                className='w-[30px] shadow h-[30px] rounded-[6px] cursor-pointer'
                style={{ backgroundColor: colorTheme?.solid_button_background }}
              ></div>
              <span className='text-sm font-medium table-text'>
                {colorTheme?.solid_button_background}
              </span>
            </div>
            {openState === 'solid_button_background' && (
              <ColorPicker
                handleChange={handleColorChange}
                setting={colorTheme?.solid_button_background}
                handleCloseColor={handleCloseColor}
              />
            )}
          </div>
        </div>
        <div className='w-full flex items-center mt-4'>
          <label className='text-[12px] font-medium text-gray-700 w-[40%]'>
            {t('lang_solid_button_text')}
          </label>
          <div className='relative w-full'>
            <div
              onClick={() => setOpenState('solid_button_text')}
              className='cursor-pointer flex items-center border main-border-color p-1.5 rounded-[6px] space-x-2'
            >
              <div
                className='w-[30px] shadow h-[30px] rounded-[6px] cursor-pointer'
                style={{ backgroundColor: colorTheme?.solid_button_text }}
              ></div>
              <span className='text-sm font-medium table-text'>
                {colorTheme?.solid_button_text}
              </span>
            </div>
            {openState === 'solid_button_text' && (
              <ColorPicker
                handleChange={handleColorChange}
                setting={colorTheme?.solid_button_text}
                handleCloseColor={handleCloseColor}
              />
            )}
          </div>
        </div>
        <div className='w-full flex items-center mt-4'>
          <label className='text-[12px] font-medium text-gray-700 w-[40%]'>
            {t('lang_outlined_button')}
          </label>
          <div className='relative w-full'>
            <div
              onClick={() => setOpenState('outlined_button')}
              className='cursor-pointer flex items-center border main-border-color p-1.5 rounded-[6px] space-x-2'
            >
              <div
                className='w-[30px] shadow h-[30px] rounded-[6px] cursor-pointer'
                style={{ backgroundColor: colorTheme?.outlined_button }}
              ></div>
              <span className='text-sm font-medium table-text'>{colorTheme?.outlined_button}</span>
            </div>
            {openState === 'outlined_button' && (
              <ColorPicker
                handleChange={handleColorChange}
                setting={colorTheme?.outlined_button}
                handleCloseColor={handleCloseColor}
              />
            )}
          </div>
        </div>
        <div className='w-full flex items-center mt-4'>
          <label className='text-[12px] font-medium text-gray-700 w-[40%]'>
            {t('lang_outlined_button_text')}
          </label>
          <div className='relative w-full'>
            <div
              onClick={() => setOpenState('outlined_button_text')}
              className='cursor-pointer flex items-center border main-border-color p-1.5 rounded-[6px] space-x-2'
            >
              <div
                className='w-[30px] shadow h-[30px] rounded-[6px] cursor-pointer'
                style={{ backgroundColor: colorTheme?.outlined_button_text }}
              ></div>
              <span className='text-sm font-medium table-text'>
                {colorTheme?.outlined_button_text}
              </span>
            </div>
            {openState === 'outlined_button_text' && (
              <ColorPicker
                handleChange={handleColorChange}
                setting={colorTheme?.outlined_button_text}
                handleCloseColor={handleCloseColor}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditingSchemeColor;
