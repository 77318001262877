import { t } from 'i18next';
import React, { useEffect, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import GenerateLiveEditiorTitle from '../../../../../controllers/GenerateLiveEditiorTitle';
import module from '../../../../../controllers/QuillToolBar';
import SvgSection from '../../../../../controllers/SvgSection';
import { FormControlLabel } from '@mui/material';
import MuiSwitch from '../../../../../controllers/MuiSwitch';
import IconList from '../IconList';

const StaticThirdContactSection = ({
  section_id,
  section_type,
  sectionContent,
  descriptionContent,
  handleThirdContactTitleValueChange,
  handleThirdContactTagChange,
  headingLevel,
  handleThirdContactDescriptionChange,
  addressValueContent,
  handleThirdContactAddressValueChange,
  emailValueContent,
  handleThirdContactEmailValueChange,
  handleThirdContactValueChange,
  thirdContactValue,
  setThirdContactValue,
  urlValueContent,
  handleThirdContactShortCodeValueChange,
}) => {
  const dropDownValue = [
    { value: 'h1', label: 'H1' },
    { value: 'h2', label: 'H2' },
    { value: 'h3', label: 'H3' },
    { value: 'h4', label: 'H4' },
    { value: 'h5', label: 'H5' },
    { value: 'h6', label: 'H6' },
    { value: 'div', label: 'Div' },
    { value: 'span', label: 'Span' },
    { value: 'p', label: 'P' },
  ];

  const [shortdescription, setShortDescription] = useState(descriptionContent);
  const [groupTitle, setGroupTitle] = useState(sectionContent);
  const [addressvalue, setAddressValue] = useState(addressValueContent);
  const [emailValue, setEmailValue] = useState(emailValueContent);
  const [selectedHeadingLevel, setSelectedHeadingLevel] = useState(headingLevel || 'h2');
  const [shortCodeValue, setShortCodeValue] = useState(urlValueContent);

  const ShortquillRef = useRef(null);
  const fileInputRef = useRef(null);

  const [meta, setMeta] = useState({
    meta_description: '',
  });

  useEffect(() => {
    const updates = {
      groupTitle: sectionContent,
      shortDescription: descriptionContent,
      addressValue: addressValueContent,
      emailValue: emailValueContent,
      shortCodeValue: urlValueContent,
    };

    setGroupTitle(updates.groupTitle);
    setShortDescription(updates.shortDescription);
    setAddressValue(updates.addressValue);
    setEmailValue(updates.emailValue);
    setShortCodeValue(updates.shortCodeValue);
  }, [sectionContent, descriptionContent, addressValueContent, emailValueContent, urlValueContent]);

  const onContentChange = (e) => {
    const newValue = e.target.value;
    setGroupTitle(newValue);
    handleThirdContactTitleValueChange(section_id, newValue);
  };

  const onAddressValueChange = (e) => {
    const newValue = e.target.value;
    setAddressValue(newValue);
    handleThirdContactAddressValueChange(section_id, newValue);
  };

  const onEmailValueChange = (e) => {
    const newValue = e.target.value;
    setEmailValue(newValue);
    handleThirdContactEmailValueChange(section_id, newValue);
  };

  const getAiGeneratedTitle = (value) => {
    setGroupTitle(value);
    handleThirdContactTitleValueChange(section_id, value);
  };

  const handleHeadingTagChange = (e) => {
    const newTag = e.target.value;
    setSelectedHeadingLevel(newTag);
    handleThirdContactTagChange(section_id, newTag);
  };

  const handleShortDescQuillChange = (value) => {
    setShortDescription(value);
    handleThirdContactDescriptionChange(section_id, value);
  };

  const [isHovered, setIsHovered] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const [activeIndex, setActiveIndex] = useState(null);

  const handleAccordionToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const handleIconClick = (value) => {
    if (value === 'iconList') {
      setIsOpen(true);
    }
  };

  const handleIconSelect = (iconComponent) => {
    const updatedEightContentValue = {
      ...thirdContactValue,
      iconArray: thirdContactValue?.iconArray?.map((item, i) =>
        i === activeIndex ? { ...item, icon: iconComponent } : item,
      ),
    };

    setThirdContactValue(updatedEightContentValue);
    handleThirdContactValueChange(section_id, updatedEightContentValue);
    setIsOpen(false);
  };

  const handleSvgClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type === 'image/svg+xml') {
      const reader = new FileReader();
      reader.onload = (e) => {
        const imageUrl = e.target.result;
        handleIconSelect(imageUrl);
      };
      reader.readAsText(file);
    } else {
      alert('Please select a valid SVG file.');
    }
  };

  const handleIconFill = (e) => {
    const { checked, name } = e.target;
    const updatedEightContentValue = {
      ...thirdContactValue,
      iconArray: thirdContactValue?.iconArray?.map((item, i) =>
        i === activeIndex ? { ...item, [name]: checked } : item,
      ),
    };

    setThirdContactValue(updatedEightContentValue);
    handleThirdContactValueChange(section_id, updatedEightContentValue);
  };

  const handleIconLink = (e) => {
    const { name, value } = e.target;
    const updatedEightContentValue = {
      ...thirdContactValue,
      iconArray: thirdContactValue?.iconArray?.map((item, i) =>
        i === activeIndex ? { ...item, link: value } : item,
      ),
    };

    setThirdContactValue(updatedEightContentValue);
    handleThirdContactValueChange(section_id, updatedEightContentValue);
  };

  const onShortCodeValueChange = (e) => {
    const newValue = e.target.value;
    setShortCodeValue(newValue);
  };

  const handleApplyChanges = () => {
    handleThirdContactShortCodeValueChange(section_id, shortCodeValue);
  };

  return (
    <div className='max-w-md mx-auto mt-4'>
      <div>
        <label>{t('lang_enter_your_short_code')}</label>
        <textarea
          className='border w-full outline-none rounded-[6px] main-border-color px-4 py-2 text-sm mt-2'
          placeholder='Add your short code here...'
          value={shortCodeValue}
          onChange={onShortCodeValueChange}
        />
      </div>
      <div className='flex justify-between mt-2 items-center'>
        <p>{t('lang_update_changes_to_form')}</p>
        <button
          type='button'
          className='inline-block bg-btn text-white text-[12px] font-medium py-1 px-3 rounded-[4px] transition duration-300'
          onClick={handleApplyChanges}
        >
          {t('lang_apply')}
        </button>
      </div>
      <div className='mb-4 mt-4'>
        <label htmlFor='group title' className='flex justify-between'>
          <span htmlFor='image'>{t('lang_Title')}</span>
          <GenerateLiveEditiorTitle
            getAiGeneratedTitle={getAiGeneratedTitle}
            section_type={section_type}
          />
        </label>
        <textarea
          className='border w-full outline-none rounded-[6px] main-border-color px-4 py-2 text-sm mt-2'
          placeholder='Add your heading text here...'
          value={groupTitle}
          onChange={onContentChange}
        />
        <label>{t('lang_title_hTMLTag')}</label>
        <div className='relative'>
          <select
            className='w-full mt-2'
            value={selectedHeadingLevel}
            onChange={handleHeadingTagChange}
          >
            {dropDownValue?.map((obj) => (
              <option key={obj?.value} value={obj?.value}>
                {obj?.label}
              </option>
            ))}
          </select>
          <div className='select-arrow'></div>
        </div>
      </div>
      <div className='w-full mb-4'>
        <label>{t('lang_Description')}</label>
        <ReactQuill
          modules={module}
          ref={ShortquillRef}
          className='w-full h-auto table-text rounded-[6px] mt-2'
          theme='snow'
          name='description'
          value={shortdescription}
          onChange={handleShortDescQuillChange}
          placeholder='Enter description'
          onBlur={() => {
            setMeta((prevMeta) => ({
              ...prevMeta,
              meta_description:
                shortdescription
                  ?.replace(/<[^>]*>/g, ' ')
                  ?.split(' ')
                  .slice(0, 50)
                  .join(' ') || '',
            }));
          }}
        />
      </div>

      <div>
        <label htmlFor='group title' className='flex justify-between'>
          <span htmlFor='image'>{t('lang_address')}</span>
        </label>
        <textarea
          className='border w-full outline-none rounded-[6px] main-border-color px-4 py-2 text-sm mt-2'
          placeholder='Add your address here...'
          value={addressvalue}
          onChange={onAddressValueChange}
        />
      </div>

      <div>
        <label htmlFor='group title' className='flex justify-between'>
          <span htmlFor='image'>{t('lang_email')}</span>
        </label>
        <input
          className='border w-full outline-none rounded-[6px] main-border-color px-4 py-2 text-sm mt-2'
          placeholder='Add your email here...'
          value={emailValue}
          onChange={onEmailValueChange}
        />
      </div>

      <div className='mt-2 space-y-3'>
        {Array.isArray(thirdContactValue?.iconArray) &&
          thirdContactValue?.iconArray?.map((val, index) => (
            <div key={index} className='border border-gray-300 rounded-md'>
              <div
                className='flex items-center justify-between p-3 bg-gray-100 cursor-pointer'
                onClick={() => handleAccordionToggle(index)}
              >
                <div className='flex items-center space-x-4'>
                  {val?.icon &&
                    (React.isValidElement(val?.icon) ? (
                      React.cloneElement(val?.icon, { width: '20px', height: '20px' })
                    ) : (
                      <SvgSection svgContent={val?.icon} width='20px' height='20px' />
                    ))}
                  <span className='text-sm font-medium'>Icon {index + 1}</span>
                </div>
                <button>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='16'
                    height='16'
                    fill='currentColor'
                    className={`transition-transform transform ${
                      activeIndex === index ? 'rotate-180' : ''
                    }`}
                    viewBox='0 0 16 16'
                  >
                    <path
                      fillRule='evenodd'
                      d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'
                    />
                  </svg>
                </button>
              </div>
              {activeIndex === index && (
                <>
                  <div
                    className='p-3 bg-white'
                    onMouseEnter={() => {
                      setIsHovered(index);
                    }}
                    onMouseLeave={() => {
                      setIsHovered(false);
                    }}
                  >
                    <label htmlFor='text' className='block mb-2 font-medium'>
                    {t('lang_icon')}
                    </label>
                    <div className='relative flex justify-center p-4 bg-[#e6e8ea] border border-[#bcbdbf]'>
                      {val?.icon &&
                        (React.isValidElement(val?.icon) ? (
                          React.cloneElement(val?.icon, { width: '22px', height: '22px' })
                        ) : (
                          <SvgSection svgContent={val?.icon} width='22px' height='22px' />
                        ))}

                      {isHovered === index && (
                        <>
                          <button
                            className='absolute bottom-0 left-0 bg-black text-xs text-white p-2 rounded-[6px]'
                            onClick={() => handleIconClick('iconList')}
                          >
                            {t('lang_icon_library')}
                          </button>
                          <button
                            className='absolute bottom-0 right-0 bg-black text-xs text-white p-2 rounded-[6px]'
                            onClick={() => handleSvgClick()}
                          >
                            {t('lang_svg_icon')}
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                  <div className='w-full mb-2 mt-2 px-3'>
                    <label htmlFor='group title' className='flex justify-between'>
                      <span htmlFor='link'>{t('lang_link')}</span>
                    </label>
                    <input
                      className='border w-full outline-none rounded-[6px] main-border-color px-4 py-2 text-sm mt-2'
                      placeholder='Add Link here...'
                      value={val?.link}
                      onChange={handleIconLink}
                      name='link'
                    />
                  </div>
                  <div className='w-full mb-4 mt-4'>
                    <label className='flex items-center justify-between pl-3 cursor-pointer'>
                      <span className='text-sm font-medium text-gray-900 dark:text-gray-300'>
                      {t('lang_icon_fill')}
                      </span>
                      <FormControlLabel
                        control={
                          <MuiSwitch
                            checked={val?.isFill || false}
                            onChange={handleIconFill}
                            name='isFill'
                            type='checkbox'
                          />
                        }
                      />
                    </label>
                  </div>
                </>
              )}
            </div>
          ))}
      </div>

      {isOpen ? (
        <IconList
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          section_id={section_id}
          handleIconSelect={handleIconSelect}
        />
      ) : (
        ''
      )}
      <div className='w-full mb-4 mt-2'>
        <input
          type='file'
          accept='.svg'
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={handleFileChange}
        />
      </div>
    </div>
  );
};

export default StaticThirdContactSection;
