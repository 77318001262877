import React, { useState, useEffect } from 'react';
import GenerateLiveEditiorTitle from '../../../../controllers/GenerateLiveEditiorTitle';
import { t } from 'i18next';

const HeadingSettingSection = ({
  section_id,
  section_type,
  sectionContent,
  headingLevel,
  handleContentChange,
  handleHtmlTagChange,
}) => {
  const [groupTitle, setGroupTitle] = useState(sectionContent);
  const [selectedHeadingLevel, setSelectedHeadingLevel] = useState(headingLevel || 'h2');

  useEffect(() => {
    setGroupTitle(sectionContent);
  }, [sectionContent]);

  useEffect(() => {
    setSelectedHeadingLevel(headingLevel);
  }, [headingLevel]);

  const onContentChange = (e) => {
    const newValue = e.target.value;
    setGroupTitle(newValue);
    handleContentChange(section_id, newValue);
  };

  const getAiGeneratedTitle = (value) => {
    setGroupTitle(value);
    handleContentChange(section_id, value);
  };

  const dropDownValue = [
    { value: 'h1', label: 'H1' },
    { value: 'h2', label: 'H2' },
    { value: 'h3', label: 'H3' },
    { value: 'h4', label: 'H4' },
    { value: 'h5', label: 'H5' },
    { value: 'h6', label: 'H6' },
    { value: 'div', label: 'Div' },
    { value: 'span', label: 'Span' },
    { value: 'p', label: 'P' },
  ];

  const handleHeadingChange = (e) => {
    const newTag = e.target.value;
    setSelectedHeadingLevel(newTag);
    handleHtmlTagChange(section_id, newTag);
  };

  return (
    <div className='secondary-bg-color w-full space-y-4'>
      <div className='mb-4'>
        <label htmlFor='group title' className='flex justify-between'>
          <span htmlFor='image'>{t('lang_title')}</span>
          <GenerateLiveEditiorTitle
            getAiGeneratedTitle={getAiGeneratedTitle}
            section_type={section_type}
          />
        </label>
        <textarea
          className='border w-full outline-none rounded-[6px] main-border-color px-4 py-2 text-sm mt-2'
          placeholder='Add your heading text here...'
          value={groupTitle?.title}
          onChange={onContentChange}
        />
      </div>
      <div className='w-full'>
        <label>{t('lang_htmltag')}</label>
        <div className='relative'>
          <select
            className='w-full mt-2'
            value={selectedHeadingLevel}
            onChange={handleHeadingChange}
          >
            {dropDownValue?.map((obj) => (
              <option key={obj?.value} value={obj?.value}>
                {obj?.label}
              </option>
            ))}
          </select>
          <div className='select-arrow'></div>
        </div>
      </div>
    </div>
  );
};

export default HeadingSettingSection;
