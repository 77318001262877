import { t } from 'i18next';
import React from 'react';

const SpacerSettingSection = ({
  section_id,
  handleSpacerWidthChange,
  spacerValue,
}) => {
  const handleWidthChange = (e) => {
    const selectedWidth = e.target.value;
    handleSpacerWidthChange(section_id, selectedWidth);
  };

  return (
    <div>
      <div className='w-full mb-4'>
        <label>{t('lang_width')} (0-600px)</label>
        <input
          type='range'
          min='0'
          max='600'
          value={spacerValue?.width || 100}
          onChange={handleWidthChange}
          className='w-full mt-2'
        />
        <div>{spacerValue?.width}px</div>
      </div>
    </div>
  );
};

export default SpacerSettingSection;
