import React from 'react';
import FooterSection from '../FooterSection';
import { ChevronDown, PlusCircle } from 'feather-icons-react/build/IconComponents';
import HomePageSectionList from './HomePageSectionList';
import HeaderSection from '../HeaderSection';
import AnnouncementSection from '../AnnouncementBar/AnnouncementSection';
import { t } from 'i18next';

const HomePageSideBar = ({
  handleChangeAnnouncement,
  isChangeHeader,
  getLayout,
  editSection,
  selectSection,
  UpdateSectionStatus,
  handleSort,
  handleGetLayout,
  hoverId,
  setHoverId,
  setFilterId,
  sectionAdd,
  handleSubItemSort,
  setSubSecId,
  loadingSections,
  openAddSection,
  handleSectionClick,
  setElementorId,
  getPrevSecId,
  updateSectionName,
  UpdateEditorSectionName,
  isEdit,
  setIsEdit,
  setIsAddSection,
  isChangeFooter,
}) => {
  return (
    <div className='theme-setting mt-4'>
      <p className='font-medium text-primary text-base px-3'>{t('lang_Home_Page')}</p>
      <hr className='my-3 border-t main-border-color'></hr>
      <span className='font-medium text-primary text-xs px-3'>{t('lang_Header')}</span>
      <details className='cursor-pointer group main-border-color'>
        <summary
          htmlFor='Meta Tag Title'
          className='text-base w-full table-text flex flex-col items-center'
        >
          <div className='flex w-full items-center justify-between'>
            <div>
              <span className='text-sm'>{t('lang_Announcement_Bar')}</span>
            </div>
            <span className='transition group-open:rotate-180'>
              <ChevronDown className=' w-4 h-5' />
            </span>
          </div>
        </summary>
        <AnnouncementSection handleChangeAnnouncement={handleChangeAnnouncement} />
      </details>
      <details className='cursor-pointer group main-border-color'>
        <summary
          htmlFor='Meta Tag Title'
          className='text-base w-full table-text flex flex-col items-center'
        >
          <div className='flex w-full items-center justify-between'>
            <div>
              <span className='text-sm'>{t('lang_Header')}</span>
            </div>
            <span className='transition group-open:rotate-180'>
              <ChevronDown className=' w-4 h-5' />
            </span>
          </div>
        </summary>
        <HeaderSection isChangeHeader={isChangeHeader} />
      </details>
      <hr className='mb-3 border-t main-border-color'></hr>
      <div className='w-full'>
        <>
          <HomePageSectionList
            getLayout={getLayout}
            editSection={editSection}
            selectSection={selectSection}
            UpdateSectionStatus={UpdateSectionStatus}
            handleSort={handleSort}
            handleGetLayout={handleGetLayout}
            hoverId={hoverId}
            setHoverId={setHoverId}
            setFilterId={setFilterId}
            sectionAdd={sectionAdd}
            handleSubItemSort={handleSubItemSort}
            setSubSecId={setSubSecId}
            loadingSections={loadingSections}
            openAddSection={openAddSection}
            handleSectionClick={handleSectionClick}
            setElementorId={setElementorId}
            getPrevSecId={getPrevSecId}
            updateSectionName={updateSectionName}
            UpdateEditorSectionName={UpdateEditorSectionName}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
          />
          <button
            onClick={(e) => {
              e.preventDefault();
              setIsAddSection(true);
            }}
            className='inline-flex items-center mx-2.5 text-link hover:underline font-medium transition-all  my-2 duration-150 text-sm'
          >
            <PlusCircle className='w-4 h-4 me-1' /> {t('lang_Add_Section')}
          </button>
        </>
      </div>
      <hr className='mb-3 border-t main-border-color'></hr>
      <details className='cursor-pointer group main-border-color'>
        <summary
          htmlFor='Meta Tag Title'
          className='text-base w-full table-text flex flex-col items-center'
        >
          <div className='flex w-full items-center justify-between'>
            <div>
              <span className='text-sm'>{t('lang_Footer')}</span>
            </div>
            <span className='transition group-open:rotate-180'>
              <ChevronDown className=' w-4 h-5' />
            </span>
          </div>
        </summary>
        <FooterSection isChangeFooter={isChangeFooter} />
      </details>
    </div>
  );
};

export default HomePageSideBar;
