import axios from 'axios';
import { ChevronDown, Plus } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';

const ColorSectionSetting = ({ handleAddToArray, colorArray,handleEditScheme  }) => {
  console.log(colorArray, 'colorArray=-=>>');
  
  const [fonts, setFonts] = useState([]);
  const API_KEY = 'YOUR_GOOGLE_FONTS_API_KEY';
  const API_URL = `https://www.googleapis.com/webfonts/v1/webfonts?key=${API_KEY}`;

  useEffect(() => {
    const fetchFonts = async () => {
      try {
        const response = await axios.get(API_URL);
        setFonts(response.data.items);
      } catch (error) {
        console.error('Error fetching Google Fonts:', error);
      }
    };

    fetchFonts();
  }, []);

  return (
    <div>
      <details className='cursor-pointer group main-border-color'>
        <summary className='text-black font-bold w-full flex items-center justify-between hover:bg-gray-100 px-3 py-2 rounded'>
          <span className='text-sm'>{t('lang_colors')}</span>
          <ChevronDown className='w-4 h-5 transition-transform group-open:rotate-180' />
        </summary>
        <div className='p-4 mt-2'>
          <p className='font-semibold'>Schemes</p>
          <p className='text-[12px] mt-2'>Color schemes can be applied to sections throughout your online store.</p>
          <div className='grid grid-cols-3 gap-2 mt-4'>
          {colorArray?.map((item, index) => {
            return (
              <div className='flex items-center flex-col' onClick={() => handleEditScheme(index)}>
                <div
                  style={{ backgroundColor: item?.background }}
                  className='p-5 h-14 w-20 rounded-[10px] flex flex-col justify-center items-center'
                >
                  <h5
                    style={{
                      color: item?.text,
                    }}
                  >
                    Aa
                  </h5>
                  <div className='space-x-2 flex'>
                    <div
                      className='h-2 w-5 rounded-[10px]'
                      style={{
                        backgroundColor: item?.solid_button_background,
                        color: item?.solid_button_text,
                      }}
                    ></div>
                    <div
                      className='h-2 w-5 rounded-[10px] border-[1px] border-solid'
                      style={{
                        borderColor: item?.outlined_button,
                        color: item?.outlined_button_text,
                      }}
                    ></div>
                  </div>
                </div>
                <p className='text-black text-[12px] w-20 text-center mt-1'>Scheme {index + 1}</p>
              </div>
            );
          })}
          <div>
            <div
              className='border-2 border-blue-500 border-dotted p-5 h-14 w-20 rounded-[10px] flex justify-center items-center'
              onClick={() => handleAddToArray("color_scheme")}
            >
              <Plus className='text-blue-500' />
            </div>
            <p className='text-blue-500 text-[12px] w-20 text-center mt-1'>Add Scheme</p>
          </div>
          </div>
        </div>
      </details>
    </div>
  );
};

export default ColorSectionSetting;
