export const staticProductData = {
  _id: '6639aed872dffe973e8179d7',
  tenant: '64a4f099bcce80fe9e1f5d6d',
  client: '644c98551fe8c8cb4b4880d7',
  user: '644c98551fe8c8cb4b4880d5',
  categories: [
    {
      _id: '6639b23e72dffe973e8199d8',
      category_name: 'Dresses & Jumpsuits',
      category_slug_name: 'dresses-jumpsuits',
      description:
        '<p><span style="background-color: rgb(245, 245, 245); color: rgb(102, 107, 120);">Dresses &amp; Jumpsuits</span></p>',
      meta_tag_title: 'Dresses & Jumpsuits',
      meta_tag_description: '  Dresses &amp; Jumpsuits  ',
      keyword: '',
      image: 'https://cdn.shopeasy.ai/shopeasy/category/category-11.jpg',
    },
    {
      _id: '6639b29372dffe973e819b70',
      category_name: "Women's Collection",
      category_slug_name: 'womens-collection',
      description:
        '<p><span style="color: rgb(102, 107, 120); background-color: rgb(245, 245, 245);">Women\'s Collection</span></p>',
      meta_tag_title: "Women's Collection",
      meta_tag_description: "  Women's Collection  ",
      keyword: '',
      image: 'https://cdn.shopeasy.ai/shopeasy/category/category-09.jpg',
    },
  ],
  product_type: 'single',
  product_name: 'Gipeli Dusuk Omuzlu T-shirt',
  product_slug_name: 'gipeli-dusuk-omuzlu-t-shirt-1',
  short_description:
    '<p>We understand that looking fabulous should also mean feeling comfortable. The "Starry Night Glamour Sequin Evening Dress" features a soft lining that caresses your skin, ensuring comfort throughout the evening.</p>',
  description:
    '<p>We understand that looking fabulous should also mean feeling comfortable. The "Starry Night Glamour Sequin Evening Dress" features a soft lining that caresses your skin, ensuring comfort throughout the evening. The dress also includes a built-in bra and adjustable straps for added support and a customized fit.</p>',
  mrp: 200,
  quantity: 65,
  stock_status: 'in_stock',
  published: true,
  dimension: {
    length: 400,
    breadth: 200,
    height: 10,
  },
  weight: 7,
  is_deleted: false,
  featured_status: false,
  image: 'https://cdn.shopeasy.ai/shopeasy/product/al5q61klvvw9f46-image.jpg',
  additional_images: [
    'https://cdn.shopeasy.ai/shopeasy/product/al5q61klvvw9fxh-image.jpg',
    'https://cdn.shopeasy.ai/shopeasy/product/al5q61klvvw9goj-image.jpg',
    'https://cdn.shopeasy.ai/shopeasy/product/al5q61klvvw9hns-image.jpg',
    'https://cdn.shopeasy.ai/shopeasy/product/al5q61klvvw9f46-image.jpg',
  ],
  sold_count: 0,
  product_tags: [],
  grouped_product_ids: [],
  min_order_quantity: 1,
  extratabs: [
    {
      _id: '64d231db4fa091ec8a822580',
      tenant: '64a4f099bcce80fe9e1f5d6d',
      tab_title: 'Size-guide',
      tab_discription: 'Size policy is the very good.',
      tab_status: true,
      client: '644c98551fe8c8cb4b4880d7',
      user: '644ca555868e697bded99ba7',
      createdAt: '2023-08-08T12:15:23.322Z',
      updatedAt: '2024-05-02T09:56:05.353Z',
      tab_id: 2,
      sort_order: 2,
    },
  ],
  related_products: [
    {
      _id: '6639ae1c72dffe973e817164',
      categories: ['64f1b6785d0b832998533b57', '6639b29372dffe973e819b70'],
      product_type: 'single',
      product_name: 'Haki Ceket Yelek Modelleri',
      product_slug_name: 'haki-ceket-yelek-modelleri',
      mrp: 96,
      stock_status: 'available',
      is_deleted: false,
      featured_status: true,
      image: 'https://cdn.shopeasy.ai/shopeasy/product/al5q61klvvw5eyc-image.jpg',
      additional_images: [
        'https://cdn.shopeasy.ai/shopeasy/product/al5q61klvvw5fvd-image.jpg',
        'https://cdn.shopeasy.ai/shopeasy/product/al5q61klvvw5grg-image.jpg',
        'https://cdn.shopeasy.ai/shopeasy/product/al5q61klvvw5hle-image.jpg',
        'https://cdn.shopeasy.ai/shopeasy/product/al5q61klvvw5eyc-image.jpg',
      ],
      sold_count: 0,
      min_order_quantity: 1,
      createdAt: '2024-05-07T04:29:16.224Z',
      selling_price: 96,
      product_discount: [],
    },
    {
      _id: '6639ae2672dffe973e8171fe',
      categories: ['64f1b6785d0b832998533b57', '6639b29372dffe973e819b70'],
      product_type: 'single',
      product_name: 'Levi Sherpa jacket Women',
      product_slug_name: 'levi-sherpa-jacket-women',
      mrp: 45,
      stock_status: 'available',
      is_deleted: false,
      featured_status: true,
      image: 'https://cdn.shopeasy.ai/shopeasy/product/al5q61klvvw5na0-image.jpg',
      additional_images: [
        'https://cdn.shopeasy.ai/shopeasy/product/al5q61klvvw5o7n-image.jpg',
        'https://cdn.shopeasy.ai/shopeasy/product/al5q61klvvw5p3g-image.jpg',
        'https://cdn.shopeasy.ai/shopeasy/product/al5q61klvvw5q1y-image.jpg',
        'https://cdn.shopeasy.ai/shopeasy/product/al5q61klvvw5na0-image.jpg',
      ],
      sold_count: 0,
      min_order_quantity: 1,
      createdAt: '2024-05-07T04:29:26.963Z',
      selling_price: 45,
      product_discount: [],
    },
    {
      _id: '6639ae3272dffe973e817293',
      categories: ['64f1b6785d0b832998533b57', '6639b29372dffe973e819b70'],
      product_type: 'single',
      product_name: 'Pink Cropped Chevron Tank',
      product_slug_name: 'pink-cropped-chevron-tank',
      mrp: 59,
      selling_price: 50,
      stock_status: 'available',
      is_deleted: false,
      featured_status: false,
      image: 'https://cdn.shopeasy.ai/shopeasy/product/al5q61klvvw5vih-image.jpg',
      additional_images: [
        'https://cdn.shopeasy.ai/shopeasy/product/al5q61klvvw5wfm-image.jpg',
        'https://cdn.shopeasy.ai/shopeasy/product/al5q61klvvw5xct-image.jpg',
        'https://cdn.shopeasy.ai/shopeasy/product/al5q61klvvw5ykd-image.jpg',
        'https://cdn.shopeasy.ai/shopeasy/product/al5q61klvvw5vih-image.jpg',
      ],
      sold_count: 0,
      min_order_quantity: 1,
      createdAt: '2024-05-07T04:29:38.247Z',
      product_discount: [],
    },
    {
      _id: '6639aea272dffe973e817774',
      categories: ['64f1b6785d0b832998533b57', '6639b29372dffe973e819b70'],
      product_type: 'single',
      product_name: 'Crop Top Beyaz Renk',
      product_slug_name: 'crop-top-beyaz-renk',
      mrp: 55,
      selling_price: 50,
      stock_status: 'available',
      is_deleted: false,
      featured_status: true,
      image: 'https://cdn.shopeasy.ai/shopeasy/product/al5q61klvvw8a7n-image.jpg',
      additional_images: [
        'https://cdn.shopeasy.ai/shopeasy/product/al5q61klvvw8b37-image.jpg',
        'https://cdn.shopeasy.ai/shopeasy/product/al5q61klvvw8c9u-image.jpg',
        'https://cdn.shopeasy.ai/shopeasy/product/al5q61klvvw8dam-image.jpg',
        'https://cdn.shopeasy.ai/shopeasy/product/al5q61klvvw8a7n-image.jpg',
      ],
      sold_count: 0,
      min_order_quantity: 1,
      createdAt: '2024-05-07T04:31:30.464Z',
      product_discount: [],
    },
  ],
  up_sell: [
    {
      _id: '6639af4372dffe973e817f9b',
      categories: ['64f1b6785d0b832998533b57', '6639b23e72dffe973e8199d8'],
      product_type: 'single',
      product_name: 'Strappy Crop Top Ruffled Overlay',
      product_slug_name: 'strappy-crop-top-ruffled-overlay',
      mrp: 56,
      stock_status: 'available',
      is_deleted: false,
      featured_status: true,
      image: 'https://cdn.shopeasy.ai/shopeasy/product/al5q61klvvwbqpk-image.jpg',
      additional_images: [
        'https://cdn.shopeasy.ai/shopeasy/product/al5q61klvvwbrls-image.jpg',
        'https://cdn.shopeasy.ai/shopeasy/product/al5q61klvvwbsdc-image.jpg',
        'https://cdn.shopeasy.ai/shopeasy/product/al5q61klvvwbtd3-image.jpg',
        'https://cdn.shopeasy.ai/shopeasy/product/al5q61klvvwbqpk-image.jpg',
      ],
      sold_count: 0,
      min_order_quantity: 1,
      createdAt: '2024-05-07T04:34:11.279Z',
      selling_price: 56,
      product_discount: [],
    },
    {
      _id: '6639af4e72dffe973e81803f',
      categories: ['64f1b6785d0b832998533b57', '6639b23e72dffe973e8199d8'],
      product_type: 'single',
      product_name: 'Roll Neck Pullover',
      product_slug_name: 'roll-neck-pullover',
      mrp: 99,
      selling_price: 89,
      stock_status: 'available',
      is_deleted: false,
      featured_status: false,
      image: 'https://cdn.shopeasy.ai/shopeasy/product/al5q61klvvwbz87-image.jpg',
      additional_images: [
        'https://cdn.shopeasy.ai/shopeasy/product/al5q61klvvwc00g-image.jpg',
        'https://cdn.shopeasy.ai/shopeasy/product/al5q61klvvwc1t1-image.jpg',
        'https://cdn.shopeasy.ai/shopeasy/product/al5q61klvvwc11a-image.jpg',
        'https://cdn.shopeasy.ai/shopeasy/product/al5q61klvvwbz87-image.jpg',
      ],
      sold_count: 0,
      min_order_quantity: 1,
      createdAt: '2024-05-07T04:34:22.699Z',
      product_discount: [],
    },
    {
      _id: '6639af5972dffe973e8180e4',
      categories: ['64f1b6785d0b832998533b57', '6639b23e72dffe973e8199d8'],
      product_type: 'single',
      product_name: 'Scallop Trim Cardigan',
      product_slug_name: 'scallop-trim-cardigan',
      mrp: 72,
      selling_price: 69,
      stock_status: 'available',
      is_deleted: false,
      featured_status: true,
      image: 'https://cdn.shopeasy.ai/shopeasy/product/al5q61klvvwc7qy-image.jpg',
      additional_images: [
        'https://cdn.shopeasy.ai/shopeasy/product/al5q61klvvwc8mg-image.jpg',
        'https://cdn.shopeasy.ai/shopeasy/product/al5q61klvvwc9je-image.jpg',
        'https://cdn.shopeasy.ai/shopeasy/product/al5q61klvvwcaeq-image.jpg',
        'https://cdn.shopeasy.ai/shopeasy/product/al5q61klvvwc7qy-image.jpg',
      ],
      sold_count: 0,
      min_order_quantity: 1,
      createdAt: '2024-05-07T04:34:33.068Z',
      product_discount: [],
    },
    {
      _id: '6639af6472dffe973e81818a',
      categories: ['64f1b6785d0b832998533b57', '6639b23e72dffe973e8199d8'],
      product_type: 'single',
      product_name: 'Solid Cropped Fitted Top',
      product_slug_name: 'solid-cropped-fitted-top',
      mrp: 90,
      stock_status: 'available',
      is_deleted: false,
      featured_status: false,
      image: 'https://cdn.shopeasy.ai/shopeasy/product/al5q61klvvwcfs6-image.jpg',
      additional_images: [
        'https://cdn.shopeasy.ai/shopeasy/product/al5q61klvvwcgy6-image.jpg',
        'https://cdn.shopeasy.ai/shopeasy/product/al5q61klvvwchy2-image.jpg',
        'https://cdn.shopeasy.ai/shopeasy/product/al5q61klvvwcis4-image.jpg',
        'https://cdn.shopeasy.ai/shopeasy/product/al5q61klvvwcfs6-image.jpg',
      ],
      sold_count: 0,
      min_order_quantity: 1,
      createdAt: '2024-05-07T04:34:44.094Z',
      selling_price: 50,
      product_badge: '',
      status_message: '',
      product_discount: [],
    },
  ],
  cross_sell: [
    {
      _id: '6639aeeb72dffe973e817a9c',
      categories: ['64f1b6785d0b832998533b57', '6639b26672dffe973e819a7d'],
      product_type: 'single',
      product_name: 'Sleeve Pullover Hoodie',
      product_slug_name: 'sleeve-pullover-hoodie',
      mrp: 80,
      quantity: 80,
      stock_status: 'available',
      is_deleted: false,
      featured_status: false,
      image: 'https://cdn.shopeasy.ai/shopeasy/product/al5q61klvvw9vqr-image.jpg',
      additional_images: [
        'https://cdn.shopeasy.ai/shopeasy/product/al5q61klvvw9wlr-image.jpg',
        'https://cdn.shopeasy.ai/shopeasy/product/al5q61klvvw9xcb-image.jpg',
        'https://cdn.shopeasy.ai/shopeasy/product/al5q61klvvw9y1y-image.jpg',
        'https://cdn.shopeasy.ai/shopeasy/product/al5q61klvvw9vqr-image.jpg',
      ],
      sold_count: 0,
      min_order_quantity: 1,
      createdAt: '2024-05-07T04:32:43.800Z',
      selling_price: 80,
      product_discount: [],
    },
    {
      _id: '6639aef672dffe973e817b38',
      categories: ['64f1b6785d0b832998533b57', '6639b26672dffe973e819a7d'],
      product_type: 'single',
      product_name: 'Kadın Kareli & Ekose',
      product_slug_name: 'kadn-kareli-ekose',
      mrp: 69,
      quantity: 69,
      stock_status: 'available',
      is_deleted: false,
      featured_status: false,
      image: 'https://cdn.shopeasy.ai/shopeasy/product/al5q61klvvwa3jj-image.jpg',
      additional_images: [
        'https://cdn.shopeasy.ai/shopeasy/product/al5q61klvvwa4hk-image.jpg',
        'https://cdn.shopeasy.ai/shopeasy/product/al5q61klvvwa5zx-image.jpg',
        'https://cdn.shopeasy.ai/shopeasy/product/al5q61klvvwa598-image.jpg',
        'https://cdn.shopeasy.ai/shopeasy/product/al5q61klvvwa3jj-image.jpg',
      ],
      sold_count: 0,
      min_order_quantity: 1,
      createdAt: '2024-05-07T04:32:54.277Z',
      selling_price: 69,
      product_discount: [],
    },
    {
      _id: '6639af3872dffe973e817ef8',
      categories: ['64f1b6785d0b832998533b57', '6639b25372dffe973e819a29'],
      product_type: 'single',
      product_name: 'Long Sleeve Mock Tops',
      product_slug_name: 'long-sleeve-mock-tops',
      mrp: 79,
      quantity: 79,
      stock_status: 'available',
      is_deleted: false,
      featured_status: false,
      image: 'https://cdn.shopeasy.ai/shopeasy/product/al5q61klvvwbi2z-image.jpg',
      additional_images: [
        'https://cdn.shopeasy.ai/shopeasy/product/al5q61klvvwbl9k-image.jpg',
        'https://cdn.shopeasy.ai/shopeasy/product/al5q61klvvwbjug-image.jpg',
        'https://cdn.shopeasy.ai/shopeasy/product/al5q61klvvwbiuj-image.jpg',
        'https://cdn.shopeasy.ai/shopeasy/product/al5q61klvvwbi2z-image.jpg',
      ],
      sold_count: 0,
      min_order_quantity: 1,
      createdAt: '2024-05-07T04:34:00.792Z',
      selling_price: 79,
      product_discount: [],
    },
    {
      _id: '6639af4372dffe973e817f9b',
      categories: ['64f1b6785d0b832998533b57', '6639b23e72dffe973e8199d8'],
      product_type: 'single',
      product_name: 'Strappy Crop Top Ruffled Overlay',
      product_slug_name: 'strappy-crop-top-ruffled-overlay',
      mrp: 56,
      quantity: 56,
      stock_status: 'available',
      is_deleted: false,
      featured_status: true,
      image: 'https://cdn.shopeasy.ai/shopeasy/product/al5q61klvvwbqpk-image.jpg',
      additional_images: [
        'https://cdn.shopeasy.ai/shopeasy/product/al5q61klvvwbrls-image.jpg',
        'https://cdn.shopeasy.ai/shopeasy/product/al5q61klvvwbsdc-image.jpg',
        'https://cdn.shopeasy.ai/shopeasy/product/al5q61klvvwbtd3-image.jpg',
        'https://cdn.shopeasy.ai/shopeasy/product/al5q61klvvwbqpk-image.jpg',
      ],
      sold_count: 0,
      min_order_quantity: 1,
      createdAt: '2024-05-07T04:34:11.279Z',
      selling_price: 56,
      product_discount: [],
    },
  ],
  product_return_policy: 'returnable',
  product_replacement_days: 7,
  sort_order: 12,
  import_porduct: true,
  product_discount: [
    {
      quantity: 10,
      discount_value: 100,
      product_discount_type: 'amount',
      start_date: '2024-05-10T09:06:06.460Z',
      end_date: '2024-07-18T09:06:06.000Z',
      _id: '663de3d65a4e8b47227762a6',
    },
    {
      quantity: 15,
      discount_value: 20,
      product_discount_type: 'percentage',
      start_date: '2024-05-10T09:07:34.113Z',
      end_date: '2024-09-27T09:07:34.000Z',
      _id: '663de3e55a4e8b47227762c3',
    },
  ],
  specification: [],
  notify: [],
  createdAt: '2024-05-07T04:32:24.264Z',
  updatedAt: '2024-05-10T09:09:34.873Z',
  product_id: 84,
  selling_price: 150,
  brand: {
    _id: '64f702063eaac819fcd95f82',
    brand_name: 'Motorola',
    brand_logo:
      'https://res.cloudinary.com/dx6tbtpcw/image/upload/v1693909503/manufacturer/j36atimji9ccxcbn1evd.png',
    brand_slug_name: 'motorola',
  },
  country_of_origin: 'India',
  default_category: {
    _id: '6639b29372dffe973e819b70',
    tenant: '64a4f099bcce80fe9e1f5d6d',
    client: '644c98551fe8c8cb4b4880d7',
    user: '644c98551fe8c8cb4b4880d5',
    category_name: "Women's Collection",
    category_slug_name: 'womens-collection',
    description:
      '<p><span style="color: rgb(102, 107, 120); background-color: rgb(245, 245, 245);">Women\'s Collection</span></p>',
    meta_tag_title: "Women's Collection",
    meta_tag_description: "  Women's Collection  ",
    keyword: '',
    image: 'https://cdn.shopeasy.ai/shopeasy/category/category-09.jpg',
    sort_order: 2,
    status: true,
    default: false,
    createdAt: '2024-05-07T04:48:19.307Z',
    updatedAt: '2024-05-10T07:18:40.363Z',
    category_id: 121,
  },
  meta_description:
    'Indulge in elegance and comfort with our Starry Night Glamour Sequin Evening Dress. Enjoy a soft lining, built-in bra, and adjustable straps for the perfect fit. Get yours now!',
  meta_title:
    'Title: Starry Night Glamour Sequin Evening DressMeta Title: Enhance Your Glamour with the Comfortable Starry Night Sequin Dress',
  model: 'tshirt',
  product_badge: 'trending product',
  product_unit: '1',
  sku_id: '7896',
  status_message: '',
  youtube_url: '',
  variants: [],
  default_sub_category: [],
  discount_percentage: -33.33,
};
