import { ChevronDown } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useState } from 'react';

const LayoutSectionSetting = ({
  fontValues,
  handleFontRangeChange,
  handleAddToArray,
  selectedFont,
}) => {
  return (
    <div>
      <details className='cursor-pointer group main-border-color'>
        <summary className='text-black font-bold w-full flex items-center justify-between hover:bg-gray-100 px-3 py-2 rounded'>
          <span className='text-sm'>{t('lang_layout')}</span>
          <ChevronDown className='w-4 h-5 transition-transform group-open:rotate-180' />
        </summary>

        <div className='p-4 mt-2'>
          <div className='flex items-center mt-2'>
            <label className='text-[12px] font-medium text-gray-700 w-[40%]'>
              {t('lang_page_width')}
            </label>
            <div className='flex flex-col w-[60%] items-center mt-2'>
              <input
                type='range'
                min='100'
                max='150'
                className='custom-slider w-full'
                onChange={handleFontRangeChange('headingFontValue')}
                value={fontValues.headingFontValue}
              />
              <div className='flex w-full items-center border border-gray-300 rounded-md px-1 mt-4'>
                <input
                  type='number'
                  min='100'
                  max='150'
                  value={fontValues.headingFontValue}
                  onChange={handleFontRangeChange('headingFontValue')}
                  className='w-full text-center focus:outline-none'
                />
                <span className='text-sm text-gray-500 mr-1'>%</span>
              </div>
            </div>
          </div>
        </div>

        <div className='p-4 mt-2'>
          <p className='font-semibold'>{t('lang_body')}</p>
          <div className='mb-6 flex'>
            <label className='text-[12px] w-[40%] font-medium text-gray-700 mt-5'>
              {t('lang_font')}
            </label>
            <div className='flex items-center w-[60%] flex-col mt-2'>
              <div
                value='Assistant'
                className='w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-1 focus:ring-blue-500 text-sm'
                onClick={() => handleAddToArray('font_scheme')}
              >
                {selectedFont}
              </div>
              <p className='text-gray-500 mt-2 text-[12px]'>{t('lang_font_description')}</p>
              <a href='#' className='text-blue-500 underline mt-1 block text-[12px]'>
                {t('lang_learn_more_system_fonts')}
              </a>
            </div>
          </div>

          <div className='flex items-center mt-2'>
            <label className='text-[12px] font-medium text-gray-700 w-[40%]'>
              {t('lang_font_size_scale')}
            </label>
            <div className='flex flex-col w-[60%] items-center mt-2'>
              <input
                type='range'
                min='100'
                max='150'
                className='custom-slider w-full'
                onChange={handleFontRangeChange('bodyFontValue')}
                value={fontValues.bodyFontValue}
              />
              <div className='flex w-full items-center border border-gray-300 rounded-md px-1 mt-4'>
                <input
                  type='number'
                  min='100'
                  max='150'
                  value={fontValues.bodyFontValue}
                  onChange={handleFontRangeChange('bodyFontValue')}
                  className='w-full text-center focus:outline-none'
                />
                <span className='text-sm text-gray-500 mr-1'>%</span>
              </div>
            </div>
          </div>
        </div>
      </details>
    </div>
  );
};

export default LayoutSectionSetting;
