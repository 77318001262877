import { ChevronDown } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useState } from 'react';

const LogoSectionSetting = ({ rangeValue, handleRangeChange }) => {
  const [logoImage, setLogoImage] = useState(null);
  const [faviconImage, setFaviconImage] = useState(null);
  const [faviconError, setFaviconError] = useState(null);

  const handleImageUpload = (e, setImage, isFavicon = false) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      const img = new Image();

      reader.onload = (event) => {
        img.onload = () => {
          if (isFavicon) {
            if (img.width === 32 && img.height === 32) {
              setImage(event.target.result);
              setFaviconError(null);
            } else {
              setFaviconError(t('Favicon must be 32x32 pixels'));
            }
          } else {
            setImage(event.target.result);
          }
        };

        img.src = event.target.result;
      };

      reader.readAsDataURL(file);
    }
  };

  const handleRemoveImage = (setImage) => {
    setImage(null);
    setFaviconError(null);
  };

  return (
    <div>
      <details className="cursor-pointer group main-border-color">
        <summary className="text-black font-bold w-full flex items-center justify-between hover:bg-gray-100 px-3 py-2 rounded">
          <span className="text-sm">{t('lang_Logo')}</span>
          <ChevronDown className="w-4 h-5 transition-transform group-open:rotate-180" />
        </summary>
        <div className="p-4 space-y-2">
          <div className="flex items-center">
            <label className="text-[12px] font-medium text-gray-700 w-[40%]">{t('lang_Logo')}</label>
            <div className="border border-dashed w-[60%] border-gray-300 rounded-md p-4 mt-2 flex flex-col justify-center items-center">
              {!logoImage ? (
                <>
                  <input
                    type="file"
                    accept="image/*"
                    id="logoUpload"
                    onChange={(e) => handleImageUpload(e, setLogoImage)}
                    className="hidden"
                  />
                  <button
                    onClick={() => document.getElementById('logoUpload').click()}
                    className="bg-gray-200 text-sm px-4 py-2 rounded-md hover:bg-gray-300"
                  >
                    {t('Upload Image')}
                  </button>
                </>
              ) : (
                <div className="flex flex-col items-center space-y-2">
                  <img src={logoImage} alt="Logo Preview" className="w-24 h-24 object-cover rounded-md" />
                  <button
                    onClick={() => handleRemoveImage(setLogoImage)}
                    className="text-red-500 text-sm px-4 py-2 rounded-md hover:underline"
                  >
                    {t('Remove')}
                  </button>
                </div>
              )}
            </div>
          </div>

          <div className="flex items-center mt-2">
            <label className="text-[12px] font-medium text-gray-700 w-[40%]">
              {t('Desktop logo width')}
            </label>
            <div className="flex flex-col w-[60%] items-center mt-2">
              <input
                type="range"
                min="50"
                max="300"
                value={rangeValue}
                onChange={handleRangeChange}
                className="custom-slider w-full"
              />
              <div className="flex w-full items-center border border-gray-300 rounded-md px-1 mt-4">
                <input
                  type="number"
                  value={rangeValue}
                  min="50"
                  max="300"
                  onChange={handleRangeChange}
                  className="w-full text-center focus:outline-none"
                />
                <span className="text-sm text-gray-500 mr-1">px</span>
              </div>
            </div>
          </div>

          <div className="flex items-center">
            <label className="text-[12px] font-medium text-gray-700 w-[40%]">
              {t('Favicon image')}
            </label>
            <div className="flex flex-col w-[60%]">
              <div className="border border-dashed border-gray-300 rounded-md p-4 mt-2 flex flex-col justify-center items-center">
                {!faviconImage ? (
                  <>
                    <input
                      type="file"
                      accept="image/*"
                      id="faviconUpload"
                      onChange={(e) => handleImageUpload(e, setFaviconImage, true)}
                      className="hidden"
                    />
                    <button
                      onClick={() => document.getElementById('faviconUpload').click()}
                      className="bg-gray-200 text-sm px-4 py-2 rounded-md hover:bg-gray-300"
                    >
                      {t('Upload Image')}
                    </button>
                  </>
                ) : (
                  <div className="flex flex-col items-center space-y-2">
                    <img src={faviconImage} alt="Favicon Preview" className="w-16 h-16 object-cover rounded-md" />
                    <button
                      onClick={() => handleRemoveImage(setFaviconImage)}
                      className="text-red-500 text-sm px-4 py-2 rounded-md hover:underline"
                    >
                      {t('Remove')}
                    </button>
                  </div>
                )}
              </div>
              {faviconError && <p className="text-xs text-red-500 mt-2">{faviconError}</p>}
              <p className="text-xs text-gray-500 mt-2">{t('Will be scaled down to 32 x 32px')}</p>
            </div>
          </div>
        </div>
      </details>
    </div>
  );
};

export default LogoSectionSetting;
