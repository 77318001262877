import { FormControlLabel } from '@mui/material';
import { t } from 'i18next';
import React, { useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import MuiSwitch from '../../../../../controllers/MuiSwitch';
import module from '../../../../../controllers/QuillToolBar';
import SvgSection from '../../../../../controllers/SvgSection';
import IconList from '../IconList';

const ListItem = ({
  dropDownValue,
  item,
  handleItemClick1,
  isSelected,
  handleInputChange,
  onCopy,
  onRemove,
  handleQuillChange,
  isHovered,
  setIsHovered,
  handleIconClick,
  handleSvgClick,
  handleIconFill,
}) => {
  const ShortquillRef = useRef(null);
  const [shortdescription, setShortDescription] = useState('');
  const [meta, setMeta] = useState({
    meta_description: '',
  });
  return (
    <div
      className={`p-3 mb-2 mt-2 border border-gray-200 rounded-md ${
        isSelected ? 'bg-gray-100 divide-slate-400 divide-y' : ''
      }`}
    >
      <div className='flex items-center justify-between'>
        <div className='flex items-center'>
          <span className='text-sm font-medium'>{item?.title}</span>
        </div>
        <div className='flex items-center space-x-3'>
          <button
            className='text-gray-400 hover:text-gray-600'
            title='Edit'
            onClick={handleItemClick1}
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='16'
              height='16'
              fill='currentColor'
              className='bi bi-pencil-square'
              viewBox='0 0 16 16'
            >
              <path d='M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z' />
              <path
                fillRule='evenodd'
                d='M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z'
              />
            </svg>
          </button>
          <button
            className='text-gray-400 hover:text-gray-600'
            title='Duplicate'
            onClick={() => onCopy(item)}
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='16'
              height='16'
              fill='currentColor'
              className='bi bi-copy'
              viewBox='0 0 16 16'
            >
              <path
                fillRule='evenodd'
                d='M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z'
              />
            </svg>
          </button>
          <button
            className='text-red-400 hover:text-red-600'
            title='Remove'
            onClick={() => onRemove(item?.id)}
          >
            <svg
              className='w-5 h-5'
              fill='none'
              stroke='currentColor'
              strokeWidth='2'
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 24 24'
            >
              <path d='M18 6l-12 12M6 6l12 12' />
            </svg>
          </button>
        </div>
      </div>
      {isSelected && (
        <div className='mt-2'>
          <div className='w-full mb-4'>
            <label htmlFor='title'>{t('lang_title')}</label>
            <input
              className='w-full px-3 mt-2 py-2 border main-border-color rounded-[6px] focus:outline-none bg-white'
              type='text'
              id='title'
              name='title'
              placeholder='Enter title...'
              value={item?.title || ''}
              onChange={handleInputChange}
            />
          </div>

          <div className='w-full mb-4'>
            <label>{t('lang_htmltag')}</label>
            <div className='relative'>
              <select
                className='w-full mt-2'
                id='htmlTag'
                name='htmlTag'
                value={item?.htmlTag || ''}
                onChange={handleInputChange}
              >
                {dropDownValue?.map((obj) => (
                  <option key={obj?.value} value={obj?.value}>
                    {obj?.label}
                  </option>
                ))}
              </select>
              <div className='select-arrow'></div>
            </div>
          </div>

          <div className='w-full mb-4'>
            <label>{t('lang_description')}</label>
            <ReactQuill
              modules={module}
              ref={ShortquillRef}
              className='w-full h-auto table-text rounded-[6px] mt-2'
              theme='snow'
              id='description'
              name='description'
              value={item?.description || ''}
              onChange={handleQuillChange}
              placeholder='Enter description'
              onBlur={() => {
                setMeta((prevMeta) => ({
                  ...prevMeta,
                  meta_description:
                    shortdescription
                      ?.replace(/<[^>]*>/g, ' ')
                      ?.split(' ')
                      .slice(0, 50)
                      .join(' ') || '',
                }));
              }}
            />
          </div>

          <div className='mt-2'>
            <label htmlFor='text'>{t('lang_icon')}</label>
            <div
              className='relative flex justify-center p-4 bg-[#e6e8ea] border-[1px] border-[#bcbdbf]'
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              {item?.icon &&
                (React.isValidElement(item?.icon) ? (
                  React.cloneElement(item?.icon, { width: '22px', height: '22px' })
                ) : (
                  <SvgSection svgContent={item?.icon} width='22px' height='22px' />
                ))}

              {isHovered && (
                <>
                  <button
                    className='text-sm absolute bottom-0 left-0 bg-black text-white p-2 rounded-[6px]'
                    onClick={() => handleIconClick('iconList')}
                  >
                    {t('lang_icon_library')}
                  </button>
                  <button
                    className='text-sm absolute bottom-0 right-0 bg-black text-white p-2 rounded-[6px]'
                    onClick={() => handleSvgClick()}
                  >
                    {t('lang_svg_icon')}
                  </button>
                </>
              )}
            </div>
          </div>

          <div className='w-full mb-2 mt-4'>
            <label className='flex items-center justify-between cursor-pointer'>
              <span className='text-sm font-medium text-gray-900 dark:text-gray-300'>
              {t('lang_icon_fill')}
              </span>
              <FormControlLabel
                control={
                  <MuiSwitch
                    checked={item?.isFill || false}
                    onChange={handleIconFill}
                    name='isFill'
                    type='checkbox'
                  />
                }
              />
            </label>
          </div>
        </div>
      )}
    </div>
  );
};

const StaticThirdStepSection = ({
  section_id,
  thirdStepValue,
  setThirdStepValue,
  handleThirdStepValue,
  tabIndex,
  setTabIndex,
  handleAddThirdStepItem,
  handleCopyThirdStepItem,
  handleThirdStepDataRemoveValue,
}) => {
  const dropDownValue = [
    { value: 'h1', label: 'H1' },
    { value: 'h2', label: 'H2' },
    { value: 'h3', label: 'H3' },
    { value: 'h4', label: 'H4' },
    { value: 'h5', label: 'H5' },
    { value: 'h6', label: 'H6' },
    { value: 'div', label: 'Div' },
    { value: 'span', label: 'Span' },
    { value: 'p', label: 'P' },
  ];

  const handleQuillChange = (content) => {
    const updatedfirstContentValue = {
      ...thirdStepValue,
      item: thirdStepValue?.item?.map((item, i) =>
        i === tabIndex ? { ...item, description: content } : item,
      ),
    };

    setThirdStepValue(updatedfirstContentValue);
    handleThirdStepValue(section_id, updatedfirstContentValue);
  };

  const handleItemClick = (index) => {
    setTabIndex((prev) => (prev === index ? null : index));
    const updatedfirstContentValue = {
      ...thirdStepValue,
      item: thirdStepValue?.item?.map((item, i) => ({
        ...item,
        isEdit: i === index,
      })),
    };
    setThirdStepValue(updatedfirstContentValue);
    handleThirdStepValue(section_id, updatedfirstContentValue);
  };
  const addItem = () => {
    const newId = thirdStepValue?.item?.length
      ? thirdStepValue?.item?.[thirdStepValue?.item?.length - 1]?.id + 1
      : 1;
    const newItem = {
      id: newId,
      title: 'Lorem',
      htmlTag: 'h2',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit in liber tempor incididunt ut labore et dolore magna aliqu',
      isEdit: false,
      icon: ` <svg
        fill='none'
        stroke='currentColor'
        stroke-linecap='round'
        stroke-linejoin='round'
        stroke-width='2'
        class='w-10 h-10'
        viewBox='0 0 24 24'
      >
        <path d='M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z'></path>
     </svg>`,
      isFill: false,
    };
    const updatedItems = thirdStepValue?.item?.map((item) => ({
      ...item,
      isEdit: false,
    }));
    const finalItems = [...updatedItems, newItem];
    handleAddThirdStepItem(section_id, finalItems);
    setTabIndex(null);
  };

  const copyItem = (item, index) => {
    handleCopyThirdStepItem(section_id, item, index);
  };

  const removeItem = (id) => {
    handleThirdStepDataRemoveValue(section_id, id);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    const updatedfirstContentValue = {
      ...thirdStepValue,
      item: thirdStepValue?.item?.map((item, i) =>
        i === tabIndex ? { ...item, [name]: value } : item,
      ),
    };

    setThirdStepValue(updatedfirstContentValue);
    handleThirdStepValue(section_id, updatedfirstContentValue);
  };

  const fileInputRef = useRef(null);
  const [isHovered, setIsHovered] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const handleIconClick = (value) => {
    if (value === 'iconList') {
      setIsOpen(true);
    }
  };

  const handleIconSelect = (iconComponent) => {
    const updatedEightContentValue = {
      ...thirdStepValue,
      item: thirdStepValue?.item?.map((item, i) =>
        i === tabIndex ? { ...item, icon: iconComponent } : item,
      ),
    };

    setThirdStepValue(updatedEightContentValue);
    handleThirdStepValue(section_id, updatedEightContentValue);
    setIsOpen(false);
  };

  const handleSvgClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type === 'image/svg+xml') {
      const reader = new FileReader();
      reader.onload = (e) => {
        const imageUrl = e.target.result;
        handleIconSelect(imageUrl);
      };
      reader.readAsText(file);
    } else {
      alert('Please select a valid SVG file.');
    }
  };

  const handleIconFill = (e) => {
    const { checked } = e.target;
    const updatedEightContentValue = {
      ...thirdStepValue,
      item: thirdStepValue?.item?.map((item, i) =>
        i === tabIndex ? { ...item, isFill: checked } : item,
      ),
    };

    setThirdStepValue(updatedEightContentValue);
    handleThirdStepValue(section_id, updatedEightContentValue);
  };

  return (
    <div className='max-w-md mx-auto mt-4'>
      <div className='mt-4'>
        <label>{t('lang_Section')}</label>
        {Array.isArray(thirdStepValue?.item) &&
          thirdStepValue?.item?.map((item, index) => (
            <ListItem
              key={index}
              item={item}
              handleItemClick1={() => handleItemClick(index)}
              isSelected={tabIndex === index}
              onCopy={() => copyItem(item, index)}
              onRemove={removeItem}
              handleInputChange={handleInputChange}
              dropDownValue={dropDownValue}
              handleQuillChange={handleQuillChange}
              isHovered={isHovered}
              setIsHovered={setIsHovered}
              handleIconClick={handleIconClick}
              handleSvgClick={handleSvgClick}
              handleIconFill={handleIconFill}
            />
          ))}
      </div>

      <button
        onClick={addItem}
        className='mt-4 px-4 py-2 bg-gray-600 text-white font-medium rounded-md hover:bg-gray-700 focus:outline-none'
      >
        + {t('lang_add_item')}
      </button>
      {isOpen ? (
        <IconList
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          section_id={section_id}
          handleIconSelect={handleIconSelect}
        />
      ) : (
        ''
      )}
      <div className='w-full mb-4 mt-2'>
        <input
          type='file'
          accept='.svg'
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={handleFileChange}
        />
      </div>
    </div>
  );
};

export default StaticThirdStepSection;
